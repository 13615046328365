.ContributorNav {
  margin-top: 4rem;
  margin-bottom: 3.5rem;
}
.active {
  color: #f16e24 !important;
}

.ContributorNav ul {
  justify-content: center;
  /*border-bottom: none;*/
}
.ContributorNav ul li a {
  /*color: #cecece;*/
  color: 7b7a7a;
  font-size: 1rem;
  /*border: none !important;*/
  cursor: pointer;
  background: #f7f7f7 !important;
  padding: 0.5rem 2rem;
}
.active:focus {
  color: #f16e24 !important;
}

.ContributorNav ul li a:hover,
.ContributorNav ul li a:active,
.ContributorNav ul li a:visited {
  color: #f16e24 !important;
  background-color: #f7f7f7 !important;
}
.ContributorNav ul li a.active {
  border-bottom: 3px solid red;
}
.NavItem.active,
.NavItem a:active {
  color: #f16e24;
  background-color: #f7f7f7 !important;
  border-bottom: 3px solid red;
}
