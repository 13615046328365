.Main {
  position: relative;
  margin-left: 7%;
  margin-right: auto;
  align-content: center;
  /* left: 35vw;
  top: 20vh; */
}

.Buyer {
  position: relative;
  /* left: 36vw;
  top: 40vh; */
}
.Seller {
  position: relative;
  /* top: 40vh;
  left: 53vw; */
  margin-left: auto;
  margin-right: 0px;
}

.Container {
  /* display: flex; */
  /* display: table-cell; */

  /* background-color: red; */
  width: 600px;
  vertical-align: middle;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
