.Upload {
  text-align: center;
}
.UploadContent {
  margin-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: 0px 8px 5px -7px #dddada;
  margin-bottom: 3px;
}
.UploadContent p {
  color: #8d8d8e;
  font-size: 1.3rem;
  letter-spacing: 4px;
}

.UploadArea {
  width: 100%;
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.UploadProfile {
  display: inline-block;
  margin-top: 3rem;
}
.Norow {
  margin-left: 0;
  margin-right: 0;
}
.LiorName {
  color: #9b9b9b;
}
.Mainp {
  font-size: 2rem;
  color: #9b9b9b;
  margin-bottom: 0;
}
.Wmap {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.Italicline {
  color: #c2c4c4;
  font-style: italic;
  font-size: 1.2rem;
  width: 70%;
  margin: -10px auto 0 auto;
}

.Or {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #c2c4c4;
  font-size: 2rem;
}
.ConnectVimeo {
  /* margin-left: 15px; */
  margin-right: 14px;
}
.ConnectVimeo:hover {
  border: 2px solid lightblue;
  border-radius: 20px;
  box-shadow: 0 0 10px #00adef inset;
  margin-right: 10px;
}

/* Upload*/
.UploadBtn {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: sub;
}

.MyBtn {
  border: none;
  color: #00adef;
  background-color: #ffffff00;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.UploadBtn input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
/* Upload*/

.Instructions {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 50%;
  font-size: large;
  /* background-color: pink; */
  /* transform: translateX(55%); */
  margin-left: auto;
  margin-right: auto;
}
.FakeButton {
  position: relative;
  border: 2px solid darkblue;
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 20px;
  margin-top: -8px;
}

.Outlier {
  /* transform: translateY(-80%) translateX(150%); */
  margin-left: 20px;
}

.UploadText {
  margin-left: auto;
  margin-right: auto;
}
