.LicensePage {
  width: 100%;
  color: #666666;
}
.LicContent {
  margin-top: 75px;
}
.ImgBanner {
  position: relative;
  margin-top: 40px;
}
.ImgBanner img {
  height: 490px;
  width: 100%;
}
.Imgtext {
  position: absolute;
  top: 20%;
  left: 22%;
}
.Imgtext {
  color: #ffffff;
}
.Imgtext h1 {
  text-transform: uppercase;
  font-size: 76px;
  margin-bottom: 45px;
  font-family: Myriad Pro Bold;
}
.Imgtext h3 {
  font-weight: 400;
  letter-spacing: 1px;
}
.Promote {
  margin-top: 50px;
}
.Impotant h6 {
  font-size: 1.1rem;
}
h5 {
  color: #333333;
  font-size: 1.1rem;
  margin: 15px 0;
}
.Check,
.Soundtrack > ul,
.Impotant > ul,
.Promote > ul,
.Share > ul,
.Print > ul,
.Notallowed > ul {
  padding-left: 0;
  padding-bottom: 10px;
}
.Check li,
.Soundtrack li,
.Impotant li,
.Promote li,
.Share li,
.Print li,
.Notallowed li {
  list-style-type: none;
  margin-bottom: 2px;
  position: relative;
  font-size: 0.9rem;
}
.Check li:before,
.Soundtrack li:before,
.Impotant li:before,
.Promote li:before,
.Share li:before,
.Print li:before {
  content: "\f164";
  width: 10px;
  height: 10px;
  position: absolute;
  left: -20px;
  top: -3px;
  font-family: "Font Awesome 5 Free";
  color: #1c8e1b;
}
.Soundtrack p {
  position: relative;
}
.Soundtrack li:nth-child(1):before,
.Notallowed li:before {
  color: red;
  margin-top: -16px;
  /*transform: rotate(180deg);*/
  left: -3px;
}
.Soundtrack li:nth-child(1):before {
  left: -18px;
  top: 13px;
}

.Notallowed li:after {
  content: "X";
  position: absolute;
  color: red;
  left: -16px;
  top: -1px;
  font-weight: 900;
}
.Promote li:nth-child(2) {
  margin-top: 15px;
}
.Promote li:nth-child(2)::before {
  content: " ";
}
.Print li,
.Share li,
.Promote li {
  margin-top: 20px;
}
.Notallowed h5 {
  margin-top: 70px;
  margin-bottom: 20px;
}
.Notallowed li {
  margin-bottom: 15px;
  color: #333333;
}
.Notallowed li:nth-child(1) {
  font-size: 16px;
}
.Notallowed li::before {
  top: 16px;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .ImgBanner img {
    height: 215px;
  }
  .Imgtext {
    left: 19%;
  }
  .Imgtext h1 {
    font-size: 22px;
    margin-bottom: 35px;
  }
  h3 {
    font-size: 1rem;
  }
  .Check,
  .Soundtrack > ul,
  .Impotant > ul,
  .Promote > ul,
  .Share > ul,
  .Print > ul,
  .Notallowed > ul {
    padding-left: 15px;
  }
}
