.User {
  width: 100%;
}

.Norow{
  margin-left: 0px;
  margin-right: 0px;
}
.AdminItem{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-top: 4rem;
  border-bottom: 1px solid #707070;
  padding-bottom: 5px;
}
.AdminItem a{
  color: #707070;
  font-size: 1.2rem;
}
.AdminItem a:hover{
  color: #f89400;
  text-decoration: none;
}
.AdminItem a:active{
  color: #f89400 !important;
  font-size: 1.2rem;
}
.AdminItem a:focus{
  outline: none;
}

.ActiveTab{
  color: #f89400 !important;
  position: relative;
}
.ActiveTab::after{
 content: "";
 position: absolute;
 width: 10px;
 height: 10px;
 background-color: #f89400;
 border-radius: 50%;
 left: 46%;
 bottom: -10px;
}

@media (min-width: 1440px) {
  .AdminItem{
    width: 74%;
    margin-left: auto;
    margin-right: auto;
  }
  input{
    font-size: 1.4rem;
  }
}
@media (min-width: 1640px) {
  .AdminItem{
    width: 74%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1740px) {
  .AdminItem{
    width: 74%;
    margin-left: auto;
    margin-right: auto;
  }
}