.fixedBox {
  /* width: 25%; */
  /* height: 50%; */
  border: 1px solid gray;
  box-shadow: 0 0 3px grey;
  padding: 10px 10px 10px 10px;
  border-radius: 20px;
  display: inline-flexbox;
}

/* .container {
  display: block;
} */

.grow {
  padding: 10px 10px 10px 10px;
  /* border: 1px solid gray;
  box-shadow: 0 0 3px grey;
  padding: 10px 10px 10px 10px;*/
  border-radius: 20px;
  /* display: inline-flexbox; */
  /* border-radius: 10px; */
  /* max-height: 50%; */
  min-width: 25%;
  max-height: 33vh;
  /* margin: 5px 1% 5px 1%; */
  /* float: left; */
  position: relative;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  /* column-count: 3; */

  /* text-align: center; */
  overflow: scroll;
}
.grow:hover {
  /* height: 150%; */
  /* width: 250%; */
  /* z-index: 10; */
  /* background-color: aquamarine; */
}

.flexible {
  display: flex;
  /* flex-direction: row;
  flex-wrap: wrap; */
  flex-flow: row wrap;
  align-content: space-around;
  background: #efefef;
}

.Selected {
  color: green;
}

.Unselected {
  color: black;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  /* border-radius: 20px; */
  /* display: none; */
}

::-webkit-scrollbar-button {
  display: none;
}
/* Track */
::-webkit-scrollbar-track {
  /* background: red; */
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}
::-webkit-scrollbar-track-piece {
  display: none;
}
::-webkit-scrollbar-corner {
  display: none;
}
::-webkit-resizer {
  display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.SelectedState {
  /* float: right;
  text-align: center; */
  border: 0;
}
