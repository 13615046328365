.Shifting {
  /* padding: 5px 5px;
  margin: -6px 30px 0px 30px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px #848181;
  height: 38px;
  text-align: center; */
  width: max-content;
  padding: 5px 12px;
  margin: 10px 33px 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 4px #848181;
  height: 38px;
  text-align: center;
}
.ShifName {
  vertical-align: -webkit-baseline-middle;
}

.activeIndex {
  color: #f99207;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="Shifting"] {
    width: max-content;
    text-align: center;
    margin: -2px;
  }
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  [class*="Shifting"] {
    width: max-content;
    text-align: center;
    margin: 10px auto;
  }
}
