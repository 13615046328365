.ContributorGuideline {
  width: 100%;
  color: #7f7f7f;
}
.Orange{
  color: #f16e24;
  font-size: 1.3rem;
}
.IntroContriTop{
  color: #999999;
  font-size: 1.3rem;
  padding: 0 46px;
}
.ImgBanner{
  position: relative;
  margin-top: 15px;
}
.ImgBack{
  height: 440px;
  width: 100%;
}
.Imgtext{
  position: absolute;
  top: 22%;
  left: 16%;
  color: #7f8181;
  text-align: center;
}
.Imgtext h1{
  font-size: 4rem;
}
.Imgtext h3{
  font-size: 2.25rem;
}
.Tratext{
  color: #ededed;
  text-align: center;
  padding: 20px 20px;
  font-size: 1.5rem;
  margin: auto auto 40% auto;
 
}

.Traingleimg{
  overflow: hidden;
    padding: 10px 80px;
}
.Video{
  margin: 0;
  text-align: center;
  padding: 60px 60px;
}
.ContributorTxt{
  padding: 0 50px;
  margin-bottom: 7rem;
}
.Videobuzz{
  color: #606060;
}
.Checklight{
  color:#c4c4c4;
}
.TratextUpper{
  border-radius: 25px;
  box-shadow: 1px 1px 4px #a59b9b;
  height: 100%;
 width: 100%;
 position: relative;
 display: flex;

}
.Topthreep{
  border: 1px solid #eadede;
  border-radius: 27px;
  margin: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.ContributorFooter{
text-align: center;
margin-top: 4rem;
margin-bottom: 4rem;
  }
.ContributorFooter span{
color: #2c65f2;
font-weight: bold;
}
.ContributorFooter p{
  color: #7f7f7f;
  font-size: .8rem;
  margin-bottom: 2rem;
}

