
@media (min-width: 1200px){
    .Mycontainer{
      max-width: 1300px;
    }
    }
    
@media (min-width: 1500px){
    .Mycontainer{
      max-width: 1400px;
    }
    }
  @media (min-width: 1600px){
        .Mycontainer{
          max-width: 1500px;
        }
        }
 @media (min-width: 1800px){
            .Mycontainer{
              max-width: 1600px;
            }
            }
 @media (min-width: 2000px){
                .Mycontainer{
                  max-width: 1800px;
                }
                }