.Gallery {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 15px;
 
}
.Norow{
  margin-left: 0px;
  margin-right: 0px;
}

.Brdrnone{
  border: none !important;
  background: none;
  font-size: 12px;
}
/* .Headinglne{
  color: #7f7f7f;
    font-weight: 700;
    letter-spacing: 5px;
    margin-bottom: 15px;
  } */
  .FilterOpt{
  background: #f7f7f7;
    border-radius: 8px;
    padding: 0 10px;
    color: #bcbcbc;
    border: 1px solid #d9d8d8;
    height: 20px;
    font-size: 10px;
}
.FilterOpt:focus, .FilterOpt:hover{
  background: #f7f7f7;
 box-shadow: none;
 color: #bcbcbc;
 border: 1px solid #d9d8d8;
}
.ResetBtn{
  min-width: 130px;
  background: #efefef;
  border-radius: 8px;
  color: #bcbcbc;
  border: 1px solid #c7c3c3;
  box-shadow: 1px 1px 2px #837d7d;
  vertical-align: top;
  height: 20px;
  font-size: 12px;
  padding: 1px 0 0 0;
}
.ResetBtn:hover{
  min-width: 130px;

  border-radius: 8px;
  background-color: #a9acaf;
  border-color: #b1aeae;
  color: #fff;
  box-shadow: 1px 1px 2px #837d7d;
  vertical-align: top;
  height: 20px;
  font-size: 12px;
  padding: 1px 0 0 0;
}
.FilterSlt{
  border: none;
    background: none;
    color: #bcbcbc;
    font-size: 14px;
}
.FilterSlt:focus {
  color: #c2bcc7;
  background-color: #fff0;
  border-color: none;
  box-shadow: 1px 1px 2px #837d7d;
  outline: 0;
 
}
.Mb0{
  margin-bottom: 0px;
}
.SearchDrop{
background-color:#393838d6;
width: 70%;
padding: 20px;
color: #fff;
}
.Category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  }
.Category p{
  padding: 5px 10px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 100;

}
.SearchDrop h6{
  color: #fff;
  font-weight: 100;
  padding: 10px 0px;
}
.InnerSrch{
  background-color: #ff000000;
  height: 24px;
  border-radius: 6px;
  width: 80%;
  margin: 0 auto;
}

/* new filter */
.FilterOpt2{
  text-align: left;
  color: #bcbcbc;
  position: absolute;
  top: -10px;
  cursor: pointer;
  padding: 0 11px;
  font-size: 14px;
}
.FilterOpt2  button{
  color: #bcbcbc;
  padding: 4px 10px;
 
}
.FilterOpt2 button:focus, .FilterOpt2 button:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #bcbcbc;
  box-shadow: none;
}

.Option{
  font-size: .75rem;
  
}
.Option:hover{
  font-size: .8rem;
  background-color: #cccccc;
}
.CogIcon{
  float: right;
}
.FilterDropmenu{
  background-color: #ffffff;
  position: relative;
  border: none;
  box-shadow: 0 0 3px #a09d9d;
  font-size: 12px;
  color: #bcbcbc;
  transform: translate3d(-12px, 30px, 0px)!important;
}
.FilterDropmenu::after{
  position: absolute !important;
  content: " ";
  width: 0; 
  height: 0; 
  top: -15px;
  left: 40%;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
 
}
.FilterDropmenu::before{
  position: absolute !important;
  content: " ";
  width: 0;
  height: 0;
  top: -16px;
  left: 39%;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 16px solid #d9d4d4;;
}

.Mgbsm15 div{
  padding-left: 7px;
  padding-right: 7px;
}















@media (max-width: 767px)  {
  .Mgbsm15 div{
margin-bottom: 15px;
  }
}
