.FooterItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}
.FooterItem a {
  color: #c7c7c7;
  text-decoration: none;
 box-sizing: border-box;
  font-size: 12px;
}

.FooterItem a:hover,
.FooterItem a:active
.FooterItem a.active {
  color: #b5b2b2;
}


@media (min-width: 500px) {
  
}
