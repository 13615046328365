.SetVimeoAccount {
  width: 100%;
  padding-top: 2rem;
}

.LoginVimeo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

/* onoff */
.OnOff {
  width: 127px;
  height: 38px;
  display: flex;
  flex-wrap: nowrap;
  text-transform: uppercase;
  justify-content: space-around;
  border: 1px solid #f3eded;
  padding-top: 8px;
  font-size: 1.2rem;
  margin: 0 10px;
}

.MySwitch {
  transform: scaleX(-1);
  height: fit-content;
  margin-right: 10px;
}

.On {
  color: #f79200;
}

.Off {
  color: #848484;
}

/* onoff */
.LogVimeo {
  /* color: #f16e24; */
  /* align-self: center; */
  /* font-size: 1.3rem; */
}

.SetVimeoform {
  margin-top: 2rem;
}

.SetVimeoform input {
  text-align: center;
  background-color: #fff0;
}

.Head {
  border-radius: 50px;
  display: inline-block;
  background: #b0b0b0;
  padding: 3px 15px;
  color: #fff;
  margin-bottom: 0;
  font-size: 1.2rem;
  padding-top: 7px;
}

.MyVideos {
  width: 100%;
  margin-top: 2rem;
  margin-right: 50em;
}

.MyvideoTB th,
.MyvideoTB td {
  border: none;
  padding: 0.2rem 0.9rem;
  vertical-align: middle;
  color: #707070;
  border-bottom: none;
}

.MyvideoTB td:last-child {
  cursor: pointer;
}

.MyvideoTB td:first-child,
.MyvideoTB th:first-child {
  padding: 0.2rem 0.9rem 0.2rem 0;
  border: none;
}

.MyvideoTB th:last-child,
.MyvideoTB td:last-child {
  background-color: #f9f9f9;
  border-bottom: none;
}

.MyvideoTB th:nth-last-child(2),
.MyvideoTB td:nth-last-child(2) {
  border: none;
}

.MyvideoTB th:last-child,
.MyvideoTB td:last-child {
  background-color: #f9f9f9;
  border: none;
  color: #b2b2b2;
}

.MyvideoTB th,
.MyvideoTB td {
  border-right: 1px solid #dee2e6;
}

.MyvideoTB tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.check-left {
  float: right;
  margin-top: 32px;
  margin-left: 0px !important;
}

/* @media (min-width: 576px)
{
    .card-columns {
        -webkit-column-count: 4;
        -moz-column-count: 4;
        column-count: 4;
    }
} */

.myColumns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 4;
}

.box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: red; */
  -webkit-box-shadow: 4px 3px 8px 1px #969696;
  box-shadow: 4px 3px 8px 1px #969696;
  border: #707070;
  margin: 5;
  padding: 20px;
}

.cardBody {
  min-height: 150px;
  max-height: 150px;
}

.selectionPreview {
  /* min-height: 400px; */
  /* max-height: 600px; */
  max-width: 80%;
}

.SelectedState {
  float: right;
  text-align: center;
  border: 0;
}

.cardControls {
  /* display: flex; */
}

/* .card-text {
    font-size: small;
} */

.flexibleh {
  display: flexbox;
  flex-flow: row wrap;
}

.selectionPreviewHeader {
  display: flexbox;
  flex-direction: row;
}

.selectionPreviewSearch {
  align-self: flex-end;
  margin-top: 10px;
  border-color: gray;
}

.selectionPreviewSorter {
  max-width: 200px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.select {
  appearance: none;
  background-color: #dbd8d8;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  /* height: 70%; */
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  align-items: center;
}

.spacedOut {
  margin-top: 50px;
}

.rightSearchinput {
  border: 1px solid gray;
  /* -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1),
    0 0 16px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.1), 0 0 16px rgba(0, 0, 0, 0.1);
  
  background: rgba(255, 255, 255, 0.5);
  margin: 0 0 10px 0; */

  border-radius: 8px;
  margin: 10px;
  padding: 10px;
  color: #bcbcbc;
  border: 1px solid #d9d8d8;
  height: 20px;
  font-size: 10px;
  max-width: 200px;
  background: #f7f7f7;
}
.FilterOpt {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 0 10px;
  color: #bcbcbc;
  border: 1px solid #d9d8d8;
  height: 20px;
  font-size: 10px;
  max-width: 200px;
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}
