.Blog {
  width: 100%;
}
.Headings{
  text-align: center;
  text-align: center;
  padding-bottom: 5rem;
}
.Headings h1{
  color: #999999;
  font-size: 5vw;
}
.Headings h4{
  color: #605f5f;
  font-size: 2.5vw;
}
.BlogOuter{
  padding: 0 100px;
}
.Subheadline{
  color: #6b6666;
  text-align: center;
  font-size: 1.5vw;
}
.Description{
  color: #6b6666;
}
.ReadMore{
  color: #000;
}
.Adsponser{
  border-radius: 20px;
  box-shadow: 2px 2px 4px #9d9696;
}

.IconReadmore{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}



.OuterIcon{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.OuterIcon a{
  padding: 0 5px;
}
.FooterItems .Norow div:first-child{
  padding-right: 18px;
  margin: auto;
}
.SocialInsta{
  width: 24px;
  height: 24px;
background-image: url("../../assets/socialicon.png");
background-position: 2px -1px;
 }
 .SocialLinked{
  width: 24px;
  height: 24px;
background-image: url("../../assets/socialicon.png");
background-position: -33px -1px;
 }
 .SocialFace{
  width: 24px;
  height: 24px;
background-image: url("../../assets/socialicon.png");
background-position: -65px -1px;
 }
 .SocialTwitt{
  width: 24px;
  height: 24px;
background-image: url("../../assets/socialicon.png");
background-position: -96px -1px;
 }