.NavItem {
  align-self: center;

}

.NavItem a.active{
  color: #f99207;
}

@media (min-width: 500px) {
  .NavItem {
  }
}
