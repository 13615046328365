.MyProfile{
    width: 100%;
}
.ProfileComplete{
    padding: 8px 15px 4px 15px;
    border: 1px solid #ddd9d9;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-radius: 15px;
    color: #456a71;
    box-shadow: 1px -1px 3px 0px #868282;
}
.ProfileComplete:hover{
text-decoration: none;
}
.Box{
    box-shadow: 1px -1px 6px #635f5f;
    padding: 5px;
}
.Box img{
    width: 100%;
}
.Box div:first-child{
    border-radius: 15px;
    overflow: hidden;
}
.ProfileComplete img{
    height: 20px;
    color: #456a71;
    
}
.BottomSec{    
    display: flex;
    flex-wrap: wrap;
    border-radius: 15px;
    overflow: hidden;
}
.BottomSec div{    
   width: 25%;
}
.LastStrip{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 15px 10px 0px 10px;
}
.Download{
    color: #1a1a1a;
    font-size: 1.2rem;
    font-weight: 800;
}
.Iconsbtm span{
    padding-left: 10px;
}