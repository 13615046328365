.Test {
  color: #bab8b8;
  border-radius: 30px;
  padding-top: 0px;
  width: 82%;
  left: 10%;
  background-color: #2b2929eb;
}
.SoundTrack {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 1px 5px #736f6f;
  border: none;
}

.SoundTrack .MHeader span {
  color: #000;
  font-weight: bold;
}
.OuterSound {
  max-height: 350px;
  overflow-x: auto;
  margin: 0 10px;
  color: #928e8f;
  font-size: 0.9rem;
}

/* width */
.OuterSound::-webkit-scrollbar {
  width: 20px;
}

/* Track */
.OuterSound::-webkit-scrollbar-track {
  background-color: #f2f0f1;
  border-radius: 10px;
}

/* Handle */
.OuterSound::-webkit-scrollbar-thumb {
  background: #c9c3c3;
  border-radius: 10px;
}

/* Handle on hover */
.OuterSound::-webkit-scrollbar-thumb:hover {
  background: #c9c3c3;
}
.SmallLine {
  font-size: 0.8rem;
  font-family: "Myriad Variable Concept Roman";
}
.ModalBody {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 14px !important;
}
.ModalBody div {
  text-align: center;
  margin-top: 10px;
}
.MHeader div:first-child {
  width: 50px !important;
  height: 50px !important;
}

.ProfilePopname {
  font-size: 8px;
  text-align: center;
  margin-bottom: 0;
  color: #d4cdcd;
  margin-top: 5px;
}
.ModalNav {
  position: absolute;
  bottom: 40px;
  left: 38%;
  justify-content: space-around;
  width: 30%;
}
.UserProfileImg p {
  font-size: 12px;
}
.ModalNav p {
  color: #ece8e8;
  font-size: 10px;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.Modalsc {
  background-color: red;
}
/* .BtnFreeDown{
    border-radius: 20px;
    position: absolute;
    right: 20px;
    top: 12px;
    background: #5b5b5b;
    color: #66d8d8!important;
    padding: 3px 10px 0px 10px;
    box-shadow: 0px 1px 4px #1e1e1e;
    border: none;
    font-size: 11px;
    letter-spacing: 2px;
  } */
.BtnFreeDown {
  /* position: absolute;
    right: -80px;
    top: 17px; */
  background: #5b5b5b;
  color: #66d8d8 !important;
  padding: 3px 10px 0px 10px;
  box-shadow: 0px 1px 4px #1e1e1e;
  border: none;
  font-size: 11px;
  letter-spacing: 2px;
  border-radius: 20px;
}
.MHeader {
  border: 0;
  padding-bottom: 0;
  padding-top: 8px;
  position: relative;
}
.MHeader button {
  margin: 0 !important;
}
.ModalBody img,
.ModalBody video {
  width: 100%;
  border-radius: 1.25rem !important;
}
.SoundTrack .MHeader h5 p {
  font-size: 1rem;
  padding-left: 15px;
}
.RadioBtn {
  display: flex;
  flex-wrap: wrap;
  background: #0000 !important;
  padding: 5px 0px;
}
.RadioBtn div {
  width: 50%;
  font-size: 14px;
  padding-right: 5px;
  text-align: left;
}
.RadioBtn div label > input {
  margin-top: 3px;
}
.SponsorAd {
  padding: 20px 20px;
  background: #6d6d6d;
  text-align: center;
  border-radius: 0px;
  margin: 20px 0 0 0;
}

.CloseBtn {
  display: inline-block;
  position: absolute;
  left: 47%;
  color: #fff;
  opacity: 1;
}

.MHeader span {
  /* display: inline-block;
    position: absolute; */
  left: 47%;
  color: #fff;
  opacity: 1;
  font-size: 20px;
  font-weight: 100;
}
.MHeader button:focus {
  outline: none;
}
.Embedd {
  word-break: break-all;
  border-radius: 10px;
  padding: 18px 10px;
  text-align: center;
  background: #181818;
  margin: 10px 0 0 0;
  font-size: 10px;
  overflow-y: auto;
}

/* width */
.Embedd::-webkit-scrollbar {
  width: 30px !important;
}

/* Track */
.Embedd::-webkit-scrollbar-track {
  background: #181818;
}

/* Handle */
.Embedd::-webkit-scrollbar-thumb {
  background: #5b5b5b;
  border-radius: 18px;
}

/* Handle on hover */
.Embedd::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.BttnLink {
  border-radius: 20px;
  background: #5b5b5b;
  color: #66d8d8 !important;
  padding: 7px 10px 1px 10px;
  box-shadow: 0px 1px 4px #1e1e1e;
  border: none;
  font-size: 11px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 80%;
  margin: 20px 0 0 0;
}

.SoundtrackLicenseModal {
}

/* .SoundtrackLicenseModal .Test .MHeader{
    border-bottom: 1px solid #dee2e6;
  } */
.SoundtrackLicenseModal .Test .MHeader h5 {
  color: inherit;
}
.SoundtrackLicenseModal .Test .MHeader span {
  position: relative;
  left: 0%;
}
.SoundtrackLicenseModal .Test .ModalBody div {
  text-align: left;
}

/* soundtrack  */

.SoundTrack .MHeader {
  color: orange;
}

.ModalMg {
  margin-top: 1.8rem;
}
.Hii {
  font-size: 4vw;

  color: #dcdcdc;
  border-radius: 20px;
  background-color: #252323;
  margin-bottom: 0;
  font-family: "Myriad Variable Concept Roman";
}
.Donate {
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 2vw;
  font-family: "Myriad Variable Concept Roman";
  margin-bottom: 0;
}
.Donate img {
  width: 20px;
}
.ThanksLink {
  width: 50%;
  bottom: 32px;
  left: 27%;
}
.ThanksLink p {
  font-size: 0.7rem;
}
.SocialIcon {
  position: absolute;
  left: -40px;
  top: 8px;
}

.SocialInsta {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/socialicon.png");
  background-position: 2px -1px;
}
.SocialLinked {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/socialicon.png");
  background-position: -33px -1px;
}
.SocialFace {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/socialicon.png");
  background-position: -65px -1px;
}
.SocialTwitt {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/socialicon.png");
  background-position: -96px -1px;
}

@media (max-width: 767px) {
  .ModalBody {
    padding: 1rem !important;
  }
  .RadioBtndiv {
    font-size: 12px;
  }
}
