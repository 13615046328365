.SetVimeoAccount {
  width: 100%;
  padding-top: 2rem;
}

.LoginVimeo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

/* onoff */
.LogVimeo {
  /* color: #f16e24; */
  /* align-self: center; */
  /* font-size: 1.3rem; */
}
