.UserProfileImg {
  width: 35px;
  border-radius: 50%;
  box-shadow: 0 0 6px #7b7a7a;
  height: 35px;
  margin: 0 auto;

}

.UserProfileImg img {
  width:100%;
 
}
