.Logo {
 
}

.Logo img {
 margin-top: -20px;
}
@media (min-width: 768px) {
    .Logo {
 display: none;
    }
  }