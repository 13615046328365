.wj-listbox {
  max-height: 200px;
}

body {
  margin-bottom: 24pt;
}

.wj-state-selected,
.wj-state-last-selected {
  background: #fff;
  color: black;
}
.buttonfield {
  display: grid;
  justify-content: center;
  margin-top: 20%;
}
#radioview {
  min-height: 300px !important;
  max-height: 300px !important;
  width: 100%;
  overflow: auto;
  cursor: default;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  background: #fff;
}
#radioviewprivete {
  max-height: 300px !important;
  width: 75%;
  overflow: auto;
  cursor: default;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  background: #fff;
}
#radioview1 {
  max-height: 300px !important;
  width: 65%;
  overflow: auto;
  cursor: default;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  background: #fff;
}
#radioviewvideo {
  max-height: 226px !important;
  width: 80%;
  overflow: auto;
  cursor: default;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  background: #fff;
  border: hidden;
}
.afg {
  margin-left: 180%;
}
.Argentine {
  margin-left: 108%;
}
.Kwanza {
  margin-left: 160%;
}
.Armenian {
  margin-left: 99%;
}
.Lek {
  margin-left: 375%;
}
.herbroew {
  margin-left: 68px;
  color: #f87e00;
  font-size: 15px;
  font-weight: 600;
  font-style: oblique;
}
.Esperantoww {
  margin-left: 30px;
  color: #f87e00;
  font-size: 15px;
  font-weight: 600;
  font-style: oblique;
}
.language_voice {
  margin-left: 15px;
  color: #f87e00;
  font-size: 15px;
  font-weight: 600;
  font-style: oblique;
}
.form-check {
  position: relative;
  display: flex;
  margin-top: 10px;
  padding-left: 2.25rem;
}
#radioviewlanguage {
  max-height: 300px !important;
  width: 460px;
  overflow: auto;
  cursor: default;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  border: hidden;
  background: #fff;
}
.radioitem {
  transition-duration: 0.4s;
  padding: 5px 10px 3px 10px;
  display: flex;
}
.radioitem:hover {
  background-color: #e6e6e6;
}
.radiolabel {
  margin-left: 10px;
}
.radiolabel1 {
  margin-left: 10px;
  display: flex;
}
#textareinptfield {
  margin-top: 12px;
  max-height: 300px !important;
  width: 100%;
  overflow: auto;
  cursor: default;
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  color: #000;
  background: #fff;
}
.headerspell {
  font-size: 21px;
  color: #0c0ca9;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.headerspell2 {
  font-size: 21px;
  color: #0c0ca9;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}
.headerspellare {
  font-size: 21px;
  color: #3f0acf;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.headerspellvideo {
  font-size: 21px;
  color: #0c0ca9;
  font-weight: 600;
  margin-left: 30px;
  margin-bottom: 25px;
  text-align: center;
}
.headerspellsybcate {
  font-size: 21px;
  color: #0c0ca9;
  font-weight: 600;
  text-align: center;
  margin-left: 15px;
}
.readyspan {
  font-size: 20px;
  font-weight: 700;
  color: #ff9d00;
}
.rready {
  text-align: center;
}
.headerspell1 {
  font-size: 20px;
  color: #7b7b85;
  font-weight: 600;
  text-align: center;
}
.radiobutton {
  background-color: initial;
  cursor: default;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  box-sizing: border-box;
  margin: 6px 8px 3px 8px;
  padding: initial;
  border: initial;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  outline: 1px solid #a8a5ab;
  border: 6px solid #ffffff;
  width: 10px;
  height: 10px;
}
.radiobutton:checked {
  border: 1px solid gray;
  border-radius: -1px;
  top: 0px;
  left: 0px;
  background: white;
  content: "✔";
  font-size: 120%;
  color: #e9efea;
  font-weight: 700;
  position: relative;
  border: 0px solid white;
  box-sizing: border-box;
  appearance: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  outline: 2px solid #cbd7cd;
  border: 6px solid #0431fa;
  width: 10px;
  height: 10px;
}
.radiobutton:checked::after {
  top: -17px;
  left: -5px;
  background: white;
  content: "✔";
  font-size: 65%;
  color: #f8faf8;
  font-weight: initial;
  position: relative;
  border: 0px solid white;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.radiobutton::after {
  border-radius: 15px;
  top: -16px !important;
  left: -4px !important;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
.serchvideolanguage {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.contain {
  display: flex;
  overflow-y: auto;
  width: 100%;
}
#greateas {
  display: flex;
  justify-content: center !important;
  width: 100%;
}
.termsspan {
  font-size: 15px;
}
.privacyspan {
  font-size: 15px;
}
.searchfield {
  display: flex;
  justify-content: center;
  align-items: center;
}
.draam {
}
.imagedram {
  width: 15px;
  height: 20px;
  margin-top: 4px;
}
.container-fluid {
  width: 100% !important;
  margin-top: 5%;
}
.wj-listbox .wj-listbox-item > label > input[type="checkbox"] {
  margin: 10px;
  width: 14px;
  height: 14px;
}

.buttonfield1 {
  display: flex;
  justify-content: center;
}
.checkboxform {
  display: inline-flex;
  justify-content: center;
  margin-top: -15px;
  padding: 28px;
  width: 100%;
}
.topping1 {
  margin-left: 15px;
}
.wj-listbox .wj-listbox-item > label > input[type="checkbox"] {
  margin: 10px;
  outline-color: #dedada;
}
.wj-listbox .wj-listbox-item {
  padding: 0px 5px 0px 8px;
}
#exampleFormControlTextarea1 {
  margin-top: 10px;
  width: 90%;
  border-radius: 10px;
}
.input-group.md-form.form-sm.form-1.pl-0 {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  margin-left: 22px;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.categoryfield {
  margin-top: 20px;
}
.videoheader {
  text-align: center;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.input-group.md-form.form-sm.form-1.pl-1 {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
/* .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.55rem + 1px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: -2.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
} */
.totalfiled {
  margin-left: 15%;
  margin-right: 15%;
  display: contents;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.addaspan {
  font-size: 15px;
  font-weight: bold;
  color: blue;
}
.descrition {
  font-size: 12px;
}
.keyword {
  display: flex;
  justify-content: center;
}
.searchcategory {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.searchsubcategory {
  margin-left: 60px;
}
.filed {
  /* display: flex; */
  justify-content: center;
  flex-wrap: wrap;
}
.checkboxvideo {
  text-align: center;
  margin-top: -54px;
  max-width: 380px;
  margin-left: 15px;
}
.fomrefield {
  margin-top: 10px;
}
.form-group {
  display: flex;
  justify-content: center;
}
.filedviode {
  /* justify-content: center;
  flex-wrap: wrap; */
  min-height: 450px;
}
.filedviodegreate {
  min-height: 450px;
  margin-top: -20px;
}
.wj-listbox {
  max-height: 300px !important;
  width: 100%;
}
.wj-control.wj-content.wj-listbox.wj-state-focus.wj-state-focused {
  height: 300px;
}
input[type="radio"]:after {
  width: 0px !important;
  height: 0px !important;
  border-radius: 15px;
  top: -7px;
  left: 1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 0px solid white;
}

input[type="radio"]:checked:after {
  width: 0px;
  height: 0px;
  border-radius: 15px;
  top: -0;
  left: -0px;
  position: relative;
  background-color: white;
  border: 0px solid white;
}
.radiogroup {
  display: flex;
  justify-content: center;
}
.yesfield {
  margin-top: 10px;
}
.nofield {
  margin-left: 60px;
  margin-top: 10px;
}
.videosound {
  margin-top: 10px;
  font-size: 22px;
  color: blue;
}
.formore {
  font-size: 15px;
  font-weight: 700;
}
.textareat {
  display: flex;
  justify-content: center;
}
