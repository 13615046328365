.VideoItem {
  /* border-radius: 20px; */
  background-color: white;
  overflow: hidden;
  box-shadow: 2px 0px 3px #aaa7a7;
  position: relative;
  height: 100%;
}

.VedioTop {
  max-height: 0;
  opacity: 0;
  transition: 2s;
}
.VideoDescription {
  max-height: 0;
  opacity: 0;
  transition: 2s;
}
.VideoItem:hover .VedioTop {
  max-height: 32px;
  top: 0px;
  opacity: 1;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.VideoItem:hover .VideoDescription {
  max-height: 32px;
  bottom: 0px;
  opacity: 1;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.VedioTopSeries {
  max-height: 32px;
  opacity: 1;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  padding: 4px 30px 2px 20px;
  margin-bottom: 0;
  color: #bcbcbc;
  font-size: 10px;
  letter-spacing: 2px;
  /* position: absolute;
    top: 0px; */
  background: #fff;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.VideoDescriptionSeries {
  max-height: 32px;
  opacity: 1;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  bottom: 0;
  position: absolute;
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 4px 2px 0px 2px;
  color: #bcbcbc;
  font-size: 10px;
  letter-spacing: 2px;
  display: flex;
  justify-content: space-between;
}
.VedioTopSeries span,
.VideoDescriptionSeries span {
  cursor: pointer;
}

.VedioTop {
  padding: 4px 30px 2px 20px;
  margin-bottom: 0;
  color: #bcbcbc;
  font-size: 10px;
  letter-spacing: 2px;
  position: absolute;
  background: #fff;
  width: 100%;
  top: -11px;
  z-index: 1;
}
.VideoDescription {
  bottom: 0;
  position: absolute;
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 4px 20px 0px 20px;
  color: #bcbcbc;
  font-size: 10px;
  letter-spacing: 2px;
  display: flex;
  justify-content: space-between;
  bottom: -11px;
}
.SeriesLink {
  text-align: center;
  color: #bcbcbc;
  margin: auto;
}
.SeriesLink:hover {
  text-decoration: none;
  color: #bcbcbc;
}
.VideoDescription span {
  cursor: pointer;
}
.VideoItemImg {
  overflow: hidden;
  height: 100%;
}
.VideoItemImg img,
.VideoItemImg video {
  width: 100%;
}
.SeriesVideoItems div span:first-child {
  color: #595959;
  font-size: 1.2rem;
  padding-right: 8px;
}
.VideoItemImg video {
  height: 100%;
  object-fit: cover;
}
.PlayersIcon {
  position: absolute;
  right: 5px;
  top: 25px;
  z-index: 1;
}

.User {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
}
.Chat {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -25px;
}
.Share {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -50px;
}
.Heart {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -75px;
}
.Music {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -100px;
}
