.bottomRight {
  max-height: 400px;
  min-width: 350px;
  position: fixed;
  bottom: 0;
  right: 0;
  /* background-color: white; */
  /* border-radius: 4px; */
  /* box-shadow: 0 0 4px black; */
  z-index: 1;
  /* overflow-y: scroll; */
  padding: 10px;
}
