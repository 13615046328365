.full_screen {
  z-index: 3;
  background: white;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.lottie_content {
  position: fixed;
  z-index: 4;
  top: 25%;
  left: 35%;
  pointer-events: none;
}
