input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -0px;
  left: 0px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -0;
  left: -0px;
  position: relative;
  background-color: #ffa500;
  border: 2px solid white;
}
input[type="radio"]:checked:before {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -0;
  left: -0px;
  position: relative;
  border: 2px solid #ffa500;
}

.Series {
  width: 100%;
  margin: auto;
  text-align: center;
  margin-bottom: 15px;
}
.Norow {
  margin-left: 0px;
  margin-right: 0px;
}
.SeriesLeft,
.SeriesMiddle,
.SeriesRight {
  background-color: #f4f4f4;
  box-shadow: 0px 0px 5px #655e5e;
  border-radius: 20px;
  min-height: 320px;
  /* max-height: 320px; */
  overflow: hidden;
  height: 100%;
}
.SeriesMiddlimg {
  min-height: 300px;
  /*max-height: 300px;*/
  overflow: hidden;
  /*height: 380px;*/
  border-radius: 20px;
  box-shadow: 1px 1px 4px #545454;
}
.SeriesLeft {
  padding-top: 12px;
}
.SeriesMiddle {
  margin: 0 auto;
  /* width: 80%; */
}
.SeriesRight {
  padding: 15px 30px;
  text-align: left;
}
.Msg {
  position: absolute;
  left: 40px;
  top: 25px;
  width: 22px;
}
.SeriesTop {
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;
  color: #7b7b7b;
  margin: 0px;
}

.SeriesMiddle video {
  width: 100%;
  /* min-height: 100%; */
  /* height: -webkit-fill-available; */
  /* height: 100%; */
}
.SeriesLeft div:first-child {
  /* height: 50px!important;
  width: 50px!important; */
}
.ProfileName {
  font-size: 12px;
  margin-bottom: 0;
  color: #949494;
  margin-top: 7px;
}
.ProfileDisc {
  font-size: 11px;
  color: #949494;
  margin-bottom: 0.5rem;
  margin-top: 5px;
}
.Rate p {
  color: #d5d5d5;
  font-size: 10px;
  margin-bottom: 0;
}
.Rate div {
  color: #f99207;
  font-size: 12px;
}
.Rate div span {
  padding: 0 5px;
}
.Mapmark {
  text-align: left;
  padding: 0px 30px 0 30px;
  font-size: 11px;
  margin-top: 20px;
}
.Mapmark p {
  margin-top: 10px;
}
.MapIcon {
  color: #cccccc;
  font-size: 14px;
  margin-right: 5px;
}

.Socialsec img {
  width: 120px;
  /*margin-top: 55px;
  margin-top: 7%;*/
}
.Socialsec p {
  margin-bottom: 0;
  font-size: 10px;
  color: #d5d5d5;
}
.SeriesMiddlecontent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  color: #545454;
  font-size: 11px;
  margin-top: 1em;
}
.SeriesMiddlecontent span {
  cursor: pointer;
}
.SeriesRightRadio {
  display: flex;
  flex-wrap: wrap;
  color: #8c8c8c;
  width: max-content;
  margin-left: -30px;
}

.SeriesRightRadioPad {
  padding: 5px;
}

.SeriesRightRadio div {
  width: 50%;
  font-size: 10px;
  font-family: "Ebrima";
}
.SeriesRightRadio div input {
  margin-top: 3px;
}

.Duration {
  padding-top: 10px;
  padding-bottom: 10px;
}
.Duration p {
  color: #bababa;
  text-align: left;
  margin-bottom: 2px;
  font-size: 11px;
  font-family: "Ebrima";
  letter-spacing: 0.5px;
  padding-left: 10px;
}

.Soundtrack,
.Serieslink p {
  color: #0d246d;
  font-family: "Ebrima";
  font-size: 14px;
  padding-top: 5px;
  text-align: left;
  margin-bottom: 3px;
}

.SoundTrack {
  padding-top: 20px;
}
.pad {
  padding-top: 10px;
}
.TextSet {
  font-size: 13px;
  padding-left: 8px;
  color: rgba(141, 54, 54, 0.637);
}
.Serieslink {
  padding-left: 8px;
  padding-top: 10px;
  max-height: 140px;
  overflow-x: auto;
  overflow-y: auto;
}
.Serieslink a,
.Serieslink span {
  color: #8e8e8e;
  font-size: 10px;
  text-align: left;
  display: block;
  margin-bottom: 2px;
}
.Soundtrack span:hover {
  color: #f99207;
}
/* .Serieslink span:hover{
  color: #f99207;
 } */
.Serieslink a:hover {
  text-decoration: none;
}
.Serieslink span {
  cursor: pointer;
}
.Serieslink span.ActiveSeris {
  color: orange;
}
hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.CarouselSlider {
  min-height: 160px;
}
.CarouselSlider ol {
  bottom: -82px;
}
.Para {
  font-size: 10px;
  color: #999999;
  text-align: left;
  padding: 0 18px;
  margin-bottom: 0;
}
.Indicator {
  /*margin-bottom: -2%;
  position: absolute !important;
  top: 135%;*/
}

.Indicator li {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #b5b5b5;
}
.container {
  position: relative;
}
.PlayersIcon {
  position: absolute;
  right: 5px;
  top: 20px;
  z-index: 1;
}

.User {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
}
.Chat {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -25px;
}
.Share {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -50px;
}
.Heart {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -75px;
}
.Music {
  background-image: url("../../../assets/Playersiconsvertical.png");
  width: 25px;
  height: 25px;
  background-position: 0 -100px;
}
@media (min-width: 1440px) {
  .SeriesMiddle {
    margin: 0 auto;
    /* width: fit-content; */
  }
}

@media (max-width: 1440px) {
  .SeriesMiddle video {
    width: 100%;
    height: -webkit-fill-available;
  }
}
@media (max-width: 767px) {
  .SeriesMiddle {
    margin: 5px;
  }
  .SeriesMiddle {
    margin: 5px;
    width: 100%;
  }
}
