.ContributorGuideline {
  /*width: 100%;*/
  color: #7f7f7f;
  padding-left: 18%;
}
.Orange {
  color: #f16e24;
  font-size: 1.3rem;
}
.IntroContriTop {
  color: #999999;
  font-size: 1.3rem;
  padding: 0 46px;
}
.ImgBanner {
  position: relative;
  margin-top: 15px;
}
.ImgBack {
  height: 440px;
  width: 100%;
}
.Imgtext {
  position: absolute;
  top: 22%;
  left: 16%;
  color: #7f8181;
  text-align: center;
}
.Imgtext h1 {
  font-size: 4rem;
}
.Imgtext h3 {
  font-size: 2.25rem;
}
.para1 {
  font-size: 1.1rem;
  word-spacing: 0.2vw;
  line-height: 30px;
  width: 80%;
  padding-left: 7%;
  font-weight: bold;
  text-align: center;
}
.para2 {
  width: 80%;
  padding-top: 5vh;
  line-height: 30px;
  word-spacing: 0.3vw;
  padding-left: 10%;
  padding-right: 2%;
  text-align: center;
}

.para3 {
  width: 80%;
  margin-top: 22vh;
  word-spacing: 0.2vw;
  padding-left: 15%;
  padding-right: 15%;
  text-align: center;
}
.para4 {
  width: 84%;
  word-spacing: 0.18vw;
  margin-left: -1.5vw;
  margin-top: 9vh;
  padding-left: 0%;
  padding-right: 0%;
  text-align: center;
}
.para5 {
  text-align: center;
  width: 81%;
  padding-left: 5vw;
  padding-right: 3vw;
  margin-top: 5vh;
}
.ImageRow {
  width: 80%;
  margin-top: 50px;
}
.ImgPara {
  text-align: center;
  margin-top: -178px;
  font-size: small;
  margin-left: -193px;
  color: #fff;
  text-shadow: 2px 1px 3px #afadad;
}
.ImgPara2 {
  margin-right: -38px !important;
}
.black {
  color: black;
}
.orange {
  color: orange;
}
.Tratext {
  color: #ededed;
  text-align: center;
  padding: 20px 20px;
  font-size: 1.5rem;
  margin: auto auto 40% auto;
}

.Traingleimg {
  overflow: hidden;
  padding: 42px 70px;
}
.Video {
  margin: 0;
  border-right: 1px solid #ccd0d4 !important;
  text-align: center;
  padding: 30px 60px;
}
.ContributorTxt {
  padding: 0 50px;
  margin-bottom: 7rem;
}
.Videobuzz {
  color: #606060;
}
.Checklight {
  color: #c4c4c4;
}
.TratextUpper {
  border-radius: 25px;
  box-shadow: 1px 1px 4px #a59b9b;
  height: 78%;
  width: 100%;
  position: relative;
  display: flex;
  z-index: 10;
}
.Topthreep {
  /*border: 1px solid #eadede;
  border-radius: 27px;
  margin: 0;
  position: absolute;
  width: 100%;
  bottom: 0;*/

  border: 0px 2px 2px 2px solid #eadede;
  border-radius: 15px;
  margin: -3px;
  margin-left: 0px;
  margin-bottom: -3px;
  box-shadow: 1px 1px 3px #a59b9b;
  position: absolute;
  width: 95%;
  bottom: 0;
}
.Footer {
  margin-right: 140px;
  text-align:center;
}
@media only screen and (max-width: 600px) {
  .ContributorTxt {
    padding: 0px 0px;
    margin-bottom: 7rem;
  }
  .para1 {
    width: 100%;
    padding-left: 0;
    margin-left: -35px;
  }
  .para2 {
    width: 100%;
    padding-left: 0%;
    padding-right: 0%;
    margin-left: -28px;
  }
  .ImageRow {
    width: 100%;
    margin-left: -30px;
  }
  .Traingleimg {
    overflow: hidden;
    padding: 42px 0px;
  }
  .ImgPara {
    margin-left: -70px;
  }
  .Topthreep {
    border: 0px 2px 2px 2px solid #eadede;
    border-radius: 15px;
    margin: -3px;
    margin-left: 0px;
    margin-bottom: -3px;
    box-shadow: 1px 1px 3px #a59b9b;
    position: absolute;
    width: 95%;
    bottom: 0;
  }
  .Video {
    padding: 3px 48px 3px 48px;
  }
  .para3 {
    width: 100%;
    margin-left: -30px;
    margin-top: 90px;
    padding-left: 0%;
    padding-right: 0;
  }
  .para4 {
    width: 100%;
    margin-left: -24px;
  }
  .para5 {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -25px;
    margin-top: 50px;
  }
}
@media screen and (min-width: 600px) and (max-width: 769px) {
  .Traingleimg {
    overflow: hidden;
    padding: 52px 0px;
  }
  .Tratext {
    padding: 10px 15px;
  }
  .para3 {
    width: 80%;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 120px;
  }
  .para4 {
    width: 102%;
    margin-left: -46px;
  }
  .para5 {
    margin-left: -18px;
    width: 89%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
