.Headinglne {
  /*color: #7d7d7d;*/
  color: #b8b3b3;
  font-weight: bold;
  letter-spacing: 3px;
  margin-top: 30px;
  margin-bottom: 45px;
  padding-left: 0px;
  padding-right: 4px;
  line-height: 40px;
  font-family: Myriad Pro Bold;
  text-align: center;
  font-size: 1.8rem;
}
.spacing {
  margin-top: 2px;
}
.ParaRoyal {
  font-size: 15px;
  color: #f16e24;
  margin-top: 45px;
  font-weight: 400;
  margin-bottom: 25px;
  text-align: center;
}
.Textorange {
  color: #f16e24;
}

@media (min-width: 768px) and (max-width: 769px) {
  .Headinglne {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .Headinglne {
    padding: 30px 0px 0px 0px;
    font-size: 1.2rem;
    line-height: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .ParaRoyal {
    font-size: 13px;
    margin-top: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .ParaRoyal {
    font-size: 10px;
    margin-top: 25px;
  }
}
