body {
  margin: 0;
  padding: 0;
  font-family: "Myriad Pro Regular";
  /* font-family: 'Ebrima';
  font-family: ' Myriad Variable Concept Roman'; */
  background: #f7f7f7;
}

html,
body,
#root,
.box {
  height: 100%;
}

.Contributor_Nav:hover,
.Contributor_Nav:active,
.Contributor_Nav:visited {
  color: #f16e24;
  background-color: #f7f7f7 !important;
}

.custom-switch .custom-control-label::before {
  left: -2.3rem;
  /* width: 2.15rem; */
}

.custom-control-label::before {
  height: 0.75rem;
  top: 0.35rem;
  background-color: #dbd8d8;
  border: #dbd8d8 solid 1px;
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #f9920754;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: #f99207;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: #f9b65b;
  border-color: #f9b65b;
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #f99207;
  background-color: #f99207;
}

.custom-switch .custom-control-label::after {
  width: calc(2rem - 14px);
  height: calc(2rem - 14px);
  top: calc(0.125rem + 1px);
  left: calc(-2.5rem + 1px);
  background-color: #fff;
  border: 1px solid #f99207;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  border: 1px solid #f99207;
}

/* .outerBox {
  display: flex;
  height: 100%;
  flex-direction: column;
} */

.btn-fix {
  width: 100%;
  padding: 2px 0 0 0;
  margin: 10px 0;
  background-color: #4848c2;
  border: none;
  font-size: 1.1rem;
  border-radius: 1px;
}