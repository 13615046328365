.Toolbar a {
    color: #cbcbcb;
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
  
}

.Toolbar{
padding-bottom: 0px!important;
}
.Toolbar div{
   justify-content: center;
    }
.Toolbar a:hover {
    color: #a2a2a2;
}


@media (max-width: 499px) {
  
}
@media (min-width:768px) and  (max-width: 769px){
  .Toolbar a {
    font-size: .8em;
  }
  }


  @media (max-width: 767px) {
  .Toolbar{
    padding-top: 20px;
    }

    .Toolbar button{
       color:#7d7d7d;
        }
}