.UploadOrg{
  width: 100%;
  padding-top: 2rem;
}
.TextSec{
  padding-right: 5rem;
}
.TextSec p, .TextSec2 p, .TextSec3 p{
  margin-bottom:.4rem;
  line-height: 36px;
  font-size: 1.1rem;  
  position: relative;
  color: #82817f;

  }
  .TextSec p:before{
   content: "X.";
   color:#e50a15;
   position: absolute;
   left: -16px;
  }
.SecHead{
  color: #282827;
  font-size: 1.4rem;
  font-weight: 900;
  margin-bottom: 2rem;
}
.TextSec2, .TextSec3{
padding-top: 3rem;
padding-right: 5rem;
font-size: 1.1rem;
color: #82817f;

}
.TextSec3 div{
margin-bottom: 2rem;
}

.TxtDark{
  color: #494743 !important;
}
.Question{
  margin: 50px 0;
}

.Question h4{
  color: #353535;
}
.Question p a{
  color: #82817f;
}

.ImgSec{
  text-align: right;
}
.ImgSec img{
 
  border-radius: 40px;
  box-shadow: 1px 1px 7px #343232;
}
.Wishlist{
  text-align: center;
  padding: 30px 0;
}
.Orange{
  color: #ff9700
}
.Cantwait{
  font-size: 1.2rem;
    color: #ff9700;
    text-align: center;
    margin-top: 7rem;
    
}

.ContributorFooter{
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
    }
  .ContributorFooter span{
  color: #2c65f2;
  font-weight: bold;
  
  }
  .ContributorFooter p{
    color: #7f7f7f;
    font-size: .8rem;
    margin-bottom: 2rem;
  }