.Collaborations {
  width: 100%;
}
.ImgBanner{
position: relative;
}
.ImgBanner img{
  width: 100%;
  }
.Bannertext{
  position: absolute;
  top:22%;
  width:100%
}
.Bannertext h1{
  color: #fff;
  font-size: 14vw;
  text-align: center;
  font-family: Myriad Pro Bold;
}
.Bannertext p{
  color: #fff;
  font-size: 3.5vw;
  text-align: center;
  font-family: Myriad Pro Bold;
}
.OuterIcon{
   display: flex;
  /*flex-wrap: wrap;
  justify-content: space-between; */
  position: absolute;
  width: 100%;
  bottom: 0%;
  padding: 7px 0 0 0;
  background-color: #f16e24;
  opacity: 0.6;
}
.SentanceHead{
  font-family: Myriad Pro Bold;
  padding: 20px 0px;
  font-size: 2.8vw;
  color: #fff;
  text-shadow: 2px 2px 3px #afadad;
  text-align: center;
}
.Sentance{
    color: #696969;
    font-size: 1rem;
    text-align: center;
    line-height: 30px;
    padding-bottom: 20px;
}

.Headings{
  font-family: Myriad Pro Bold;
  padding: 20px 0px;
  font-size: 2.8rem;
  color: #fff;
  text-shadow: 2px 2px 3px #afadad;
  text-align: center;
}

.OuterIcon p{
  margin-bottom: 0;
  color: #fff;
  font-size: .7rem;

}
.ImgIcon{
  width: 40px;
  height: 40px;
  display: inline-block;
}
.Myinput{
  border-radius: 10px;
  padding-left: 5px;
  color: #7f7973;
  font-family: Myriad Pro Bold;
  resize: none;
  border: none;
  box-shadow: 1px 1px 6px #c3c0c0;
}
.Send{
  background: none;
  border: none;
  color: #7f7973;
  font-family: Myriad Pro Bold;
  font-size: 2rem
}

.Every{
  font-size: .8rem;
    text-align: center;
    color: #ededed;
    font-family: Myriad Pro Bold;
}
.MoreDetail{
  font-size: 4vw;
  text-align: center;
  color: #918c86;
  font-family: Myriad Pro Bold;
}

@media only screen and (max-width:600px){
  .row{
    display:flex;
    justify-content: start !important;
  }
  .ImgIcon {
    width: 15px;
    height: 10px;
    display: inline-block;}
/* .OuterIcon p{
  font-size: 0.4rem;
  display: flex;
  flex-wrap: initial;
} */
.OuterIcon{
  display: none;
}
.Send{
  font-size: 1.3rem;
}
  }
