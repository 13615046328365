.Norow {
  margin-left: 0px;
  margin-right: 0px;
}

.Btnintgrp {
  border-top-left-radius: 9px !important;
  border-color: #949292;
  background-color: #f7f7f7;
  color: #bcbcbc;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  font-size: 11px;
  padding: 3px 12px 0 12px;
}
.Btnintgrp:hover {
  border-top-left-radius: 9px !important;
  background-color: #a9acaf;
  border-color: #b1aeae;
  color: #fff;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  font-size: 11px;
  padding: 3px 12px 0 12px;
}
.Btnigrp {
  border-top-left-radius: 9px !important;
  border-color: #949292;
  background-color: #f7f7f7;
  color: #bcbcbc;
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  font-size: 11px;
  padding: 3px 12px 0 12px;
  cursor: pointer;
}

.Brdrnone::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bcbcbc;
}
.InnerSrch::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
}
.Search {
  background-color: #f7f7f7;
  box-shadow: -1px -1px 2px #939191;
  border-bottom: 2px solid #aca5a5;
  border-radius: 10px;
  width: 60%;
  /*width: 61% !important;*/
  margin: 55px auto 0 auto;
  color: #bcbcbc;
}

.Brdrnone {
  color: #bcbcbc !important;
  border: none !important;
  background: none;
  font-size: 10px;
  padding: 0 5px 0 5px;
}

.Brdrnone:focus {
  border: none !important;
  background: none;
  box-shadow: none;
}

.Brdrnone::placeholder {
  color: #bcbcbc;
  /* opacity: 1; */
}

.Mb0 {
  margin-bottom: 0px;
}
.SearchDrop {
  background-color: #393838d6;
  width: 85%;
  padding: 20px;
  color: #fff;
  transform: translate3d(339px, 30px, 0px) !important;
  border-radius: 12px;
  border-top-left-radius: 0;
}
.Overflow {
  height: 75vh;
  overflow: auto;
}

/* width */
.Overflow::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.Overflow::-webkit-scrollbar-track {
  background-color: #393838d6;
  border-radius: 10px;
}

/* Handle */
.Overflow::-webkit-scrollbar-thumb {
  background: #959191;
  border-radius: 10px;
  height: 30px;
}

.Category {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /*justify-content: space-between;*/
}
.Category span {
  padding-left: 5px;
  width: 25%;
  margin-bottom: 0.6rem;
}
.Category p {
  /*padding: 12px 12px;
   border: 1px solid #ced4da;
  border-radius: 10px;*/
  font-size: 12px;
  font-weight: 100;
  margin-bottom: 5px;
  color: #fff;
}
.SearchDrop h6 {
  color: #fff;
  font-weight: 100;
  padding: 10px 0px;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.InnerSrch {
  background-color: #ff000000;
  height: 20px;
  border-radius: 6px;
  width: 80%;
  margin: 0 auto;
  font-size: 0.7rem;
  letter-spacing: 2px;
  font-family: "BAHNSCHRIFT";
}
.InnerSrch:focus {
  color: #ffffff;
  background-color: #fff0;
  border-color: #e6e4e4;
  outline: 0;
  box-shadow: none;
}
.Cateheading {
  color: orange;
  font-family: "corbel";
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.Checked p {
  color: orange !important;
  font-size: 12px;
  font-weight: 100;
  margin-bottom: 5px;
  position: relative;
}
.Checked p:after {
  content: " ";
  position: absolute;
  background-image: url("../../../assets/Checked.png");
  width: 10px;
  height: 10px;
  top: 3px;
  left: -13px;
  background-repeat: no-repeat;
}
.SocialFace {
  width: 24px;
  height: 24px;
  background-image: url("../../../assets/socialicon.png");
  background-position: -65px -1px;
}

@media (min-width: 768px) and (max-width: 769px) {
  .Headinglne {
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1.5rem;
  }
  .Search {
    width: 80%;
    margin: 35px auto 0px;
  }
}
@media (max-width: 767px) {
  .Headinglne {
    padding: 0;
    font-size: 1.2rem;
    line-height: 20px;
  }
  .Search {
    width: 100% !important;
    margin: 35px auto 0px;
  }
  .ParaRoyal {
    font-size: 14px;
  }
}
