
.NavItems:nth-child(1) li:nth-child(1) {
  margin-left: 0;
  margin-right: 30px;
}
.NavItems:nth-child(1) li:nth-child(1) a img {
  width: 110px;
  margin-bottom: 10px;
}

.NavItems:nth-child(3) li:nth-child(2) a {
  color:#f99207;

}
.NavItems:nth-child(3) li:nth-child(3) span {
  color:#bcbcbc;
  cursor: pointer;
}
.NavItems:nth-child(3) li:nth-child(3) span:hover {
  color: #a2a2a2;
}
.NavItems:nth-child(3) li:nth-child(2){
 margin-right: 0;
}
.NavItems:nth-child(3) li:nth-child(4) a {
  font-size: 18px;
}
.NavItems:nth-child(3) li:nth-child(2) span{
  color: #bcbcbc;
  margin-left: 10px;
}
.NavItems:nth-child(3) li {
 margin-right: 20px;
}
.NavItems {
  font-size: 14px;
}
.NavItems:nth-child(1) li{
  margin-left: 30px;
}
.Navimg img{
  width: 20px;
  margin:0 10px;
}
.NavItemsIcon{
  margin: 0 30px;
}
.NavItemsIcon li a{
  padding: 0 5px;
}
@media (min-width: 500px) {
  .NavItems {
  }
}

@media (max-width: 767px) {
  .NavItems:nth-child(1) li:nth-child(1) {
   display: none;
  }
}
@media (max-width: 768px) and (min-width:767px){
  .NavItems:nth-child(1) li:nth-child(1) {
    margin-right: 0px;
  }
.NavItems:nth-child(1) li{
  margin-left: 0px;
}
.NavItems:nth-child(3) li {
  margin-right: 0px;
}
.Navimg img{
  width: 20px
}
.NavItemsIcon{
  justify-content: center;
}

}
@media (max-width: 768px) and (min-width:767px){
  .NavItems:nth-child(1) li:nth-child(1) {
    margin-right: 0px;
  }
.NavItems:nth-child(1) li{
  margin-left: 0px;
}
.NavItems:nth-child(3) li {
  margin-right: 0px;
}
.Navimg img{
  width: 20px
}
}
@media (max-width: 767px){

.NavItemsIcon{
  justify-content: center;
}

}