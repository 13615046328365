.Notification div.dropdown-menu.show{
    border-bottom-left-radius: 30px;
}

.Notification p{
 font-size: 12px;
 margin-bottom: 0px;
padding-top: 0;

}
.NotificatioHead{
background-color: #303030;
position: absolute;
top: 0;
width: 100%;
color: #fff;
text-align: center;
padding: 1px 8px;
font-size: 12px;
}
.CloseNtifcation{
    float: right;
}
.SubHead{
    margin-top: 11px;
    padding: 8px 18px;
    background: #fff;
    box-shadow: 0 1px 11px #d6d4d4;
    display: flex;
    justify-content: space-between !important;
    position: relative;
    font-size: 14px;
}
button:focus {
    outline: none;
}
.SubHead p{
    font-size: 14px;
}
.SubHead i{
    padding: 0px 8px 0 24px;
    font-size: 20px;
}
.Badges{
    color: #fff;
    background: #f99207;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 12px;
    position: absolute;
    top: 2px;
}

.ItemNotification button{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 2px 5px;
    color: #acacac;

}
.ItemNotification button div{
    align-self: center;
    padding: 0 5px;
}
.ItemNotification button:nth-child(odd){
  background-color: #f9f9f9;

}
.ItemNotification button:nth-child(even){
    background-color: #f4f4f4
  
  }
.ItemNotification{
    min-width: 500px;
    padding-top: 10px;
    min-height: 400px;
    overflow-y: auto;
    background: #f9f9f9;
}
.MsgNotification{
    width: 100%;
    white-space: normal;
}
.liveIcon{
    color: green;
    font-size: 12px;
}
.FtrNotification{
    padding: 1px 10px;
    text-align: center;
}
.Setting, .ChatNote{
    float: right;
    font-size: 12px;
}
.Setting{
    color: #acacac;
    padding-left: 20px;
}
.Setting i{
    padding-right: 5px;
}

.ChatNote{
    color: #f99207;
   
}
/* .Topic{
    padding-right: 20px;
} */






@media (max-width: 767px)  {
    .ItemNotification{
        min-width: 300px;
    }
  }