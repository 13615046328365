.FaqPage {
  width: 100%;
  color: #666666;
}
.ParaRoyal {
  font-size: 15px;
  color: #f16e24;
  margin-top: 45px;
  font-weight: 400;
  margin-bottom: 45px;
  padding-left: 0%;
  text-align: center;
}

.FaqBanner img {
  width: 100%;
}
.FaqBanner {
  position: relative;
  height: 330px;
  overflow: hidden;
}
.Faq {
  position: absolute;
  top: 10%;
  left: 36%;
}
.Faq p {
  color: #fff;
  font-size: 15vw;
  text-shadow: 1px 1px 4px #888282;
  font-family: "Bahnschrift";
  letter-spacing: 0.5vw;
  font-weight: 600;
}
.FaqCollapse {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px #bfbebe;
  background-color: white;
  border-radius: 25px;
}
.PlusBtnDiv {
  padding: 25px 30px;
  box-shadow: 1px 1px 10px #bfbebe;
  background-color: white;
  border-radius: 25px;
  display: flex;
}
.PlusBtnDiv p {
  text-align: center;
  width: 95%;
  margin-bottom: 0;
  /* font-size: 1.5vw; */
  font-size: 22px;
  color: #a09c9c;
  padding-top: 5px;
  box-sizing: border-box;
}

.PlusBtnDiv button {
  text-align: center;
  border: none;
  background: none;
  font-size: 1.5rem;
  font-weight: 800;
  color: #a09c9c;
}

.AnswerP {
  text-align: center;
  color: #6c6b6ceb;
  padding: 30px 0px;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 600px;
}
.Ans {
  color: #f99207;
  font-size: 0.7rem;
  padding: 5px 25px;
}
@media (min-width: 1440px) {
  .Faq {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .Faq p {
    color: #fff;
    font-size: 14vw;
    text-shadow: 1px 1px 4px #888282;
    font-family: "BAHNSCHRIFT";
    margin: auto;
  }
}
@media only screen and (max-width: 600px) {
  .FaqBanner {
    height: 110px;
  }
  .PlusBtnDiv {
    padding: 15px;
  }
  .PlusBtnDiv p {
    font-size: 19px;
  }
  .AnswerP {
    padding: 15px;
    font-size: 15px;
  }
  .PlusBtnDiv button {
    font-size: 1.4rem;
  }
  .ParaRoyal {
    font-size: 12px;
    /*margin-top: 30px;*/
  }
}
@media only screen and (min-width: 601px) and (max-width: 769px) {
  .PlusBtnDiv p {
    font-size: 18px;
  }
  .AnswerP {
    padding: 7px;
    font-size: 19px;
  }
}
