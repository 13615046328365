.GeneralStatus{
    width: 100%;
}
.Roundover{
    width: 50%;
    height: 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    padding: 30px 0;

}

.Circle{
    width: 130px;
    height: 130px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 7px #d6cbcb;
    padding: 15px 10px;
    text-align: center;
  }

  .Circle p{
   margin-bottom: 0;
   color: #a0a0a0;
   line-height: normal;
   font-size: 1.4rem;
  }
/* .Roundover p{
    position: relative;
  }
.Roundover p::before{
  content: " ";
  position: absolute;
  width: 120px;
  height: 120px;
  background-color: #c0bcbc;
  border-radius: 50%;
  top: 0;
  left: 0;
  z-index: -1;
  -webkit-filter: blur(3px);
}
.Roundover p::after{
    content: " ";
    position: absolute;
    width: 105px;
    height: 105px;
    background-color: white;
    border-radius: 50%;
    top: 7px;
    left: 7px;
    z-index: -1;
    -webkit-filter: blur(1px);
  } */