.ContributorNav {
  margin-top: 4rem;
  margin-bottom: 3.5rem;
}

.ContributorNav ul {
  justify-content: center;
  border-bottom: none;
}
.ContributorNav ul li a {
  color: #cecece;
  font-size: 1rem;
  border: none !important;
  cursor: pointer;
  background: #f7f7f7 !important;
  padding: 0.5rem 2rem;
}
.ContributorNav ul li a:hover,
.ContributorNav ul li a:active,
.ContributorNav ul li a:visited {
  color: #f16e24;
  background-color: #f7f7f7 !important;
}
.NavItem.active,
.NavItem a:active {
  color: #f16e24;
  background-color: #f7f7f7 !important;
}

@media (min-width: 1440px) {
  .ContributorNav ul li a {
    font-size: 1.5rem;
  }
}
