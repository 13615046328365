.PersonalDetails{
    width: 100%;
    padding-top: 4rem;
}
.PersonalDetailsForm{
    /* display: flex;
    flex-wrap: wrap; */
    }
.PersonalDetailsForm input, .PersonalDetailsForm textarea, .PersonalDetailsForm .OnOffouter{
width: 48%;
margin: 2px 3px;
resize: none;
float: left;
}
.Personelinput{
    background-color: #ffffff0a;
}
.Personelinput:focus {
    color: #848484;
    background-color: #fff0;
    border-color: #d0cece;
    outline: 0;
    box-shadow: none;
}

/* onoff */
.OnOffTgl{
  
    height: 38px;
    display: flex;
    flex-wrap: nowrap;
    text-transform: uppercase;
    border: 1px solid #e1e1e1;
    padding: 7px 16px;
    font-size: 1.2rem;    
    margin-bottom: 0.2rem;
    justify-content: space-between;
}
.MySwitch{
   
    height: fit-content;
}
.MySwitch:after{
    transform: scaleX(-1);
}
.MySwitch:before{
    background-color: #f79f1f;
    border: none;
    height: .8rem;
    top: 0.32rem;
}

/* onoff */
.PreviewHead{
    font-size: 1.7rem;
    color: #aeafae;
    text-align: right;
}
.Uploadsquare{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 2rem;
}
.Squares{
    padding: 20px 50px;
    background-color: white;
    box-shadow: 0 0 0 10px #e5dddd;
    text-align: center;
}
.Squares p{
    font-size: 2rem;
    margin-bottom: 0;
}

/* Upload*/
.UploadBtn{
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .MyBtn {
    border: none;
    color: #3e17ff;
    background-color: #ffffff00;
    text-transform: uppercase;
    font-size: .9rem;
  }
  
  .UploadBtn input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
/* Upload*/

/*Modal*/
/* .ModAll{
    border-radius: 20px;
    overflow: hidden;
    margin-top: 1.8rem;
    width: 50vw;
    border: 1px #dcdcdc;
     
    
}*/
/*Modal*/

/*ModalHeader*/
/* .MHead{
    background-color:  #dcdcdc;
} */
/*ModalHeader*/

/*Modalbody*/
 /* .ModlBdy {
   background-color:  #dcdcdc;
    padding: 1rem !important;
    font-family: Myriad Variable Concept Roman;
    margin: auto;
    width: 100%;
    height: 70%; 
    
}*/
/*Modalbody*/

.SeriesLeft {
    background-color: #dcdcdc;
    /* box-shadow: 0px 0px 5px #655e5e; */
    border-radius: 20px;
    min-height: 320px;
    /* max-height: 320px; */
    overflow: hidden;
    height: 100%;
}

.ProfileName {
    font-size: 12px;
    margin-bottom: 0;
    color: black;
    margin-top: 7px;
    text-align: center;
  }

.ProfileDescription {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ProfileDescription p {
    color: black;
    /* text-align: left; */
    margin-bottom: 2px;
    font-size: 11px;
    font-family: "Ebrima";
    letter-spacing: 0.5px;
    padding-left: 10px;
    padding-right: 10px;
}

.UserProfileImg {
    width: 35px;
    border-radius: 50%;
    box-shadow: 0 0 6px #7b7a7a;
    height: 35px;
    margin: 0 auto;
  
  }
  
  .UserProfileImg img {
    width:100%;
   
  }

