.VideoItems {
  /* display: inline-block; */
}

.DFlex {
  display: flex;
  padding: 40px 0;
}

.Mgtopnegativ {
  margin-top: -110px;
}
.Mgtopnegativ2 {
  margin-top: -250px;
}
.Mgtopnegativ3 {
  margin-top: -280px;
}
.UploadBtn {
  min-width: 120px;
  background: #f7f7f7;
  border-radius: 8px;
  padding: 0 0;
  color: #bcbcbc;
  border: none;
  box-shadow: 1px 1px 2px #837d7d;
  vertical-align: top;
  height: 20px;
  margin: auto;
  font-size: 12px;
}

.SeriesVideoItems {
  margin-left: 0;
  margin-right: 0;
}
.VedioSec {
  margin-top: 30px;
}

.ShiftingBottom.ShiftingBottom {
  width: max-content !important;
  /*padding-left: 36%;
    padding-right: 30%;*/
  margin: 0;
  align-items: center;
  margin: auto !important;
  padding-top: 25px;
}

.SeriesText {
  /* color: #a5a5a5;
    font-size: 1.4vw;
    text-shadow: 1px 1px 1px #cacaca;
    padding-left: 100px; */
  color: #a5a5a5;
  font-size: 1.4vw;
  margin: 20px 20px 20px 35%;
  text-shadow: 1px 1px 1px #cacaca;
  width: 210%;
}
.SeriesText span {
  color: #595959;
  padding-right: 10px;
}

.SeriesTitleDiv {
  display: flex;
  margin-bottom: 20px;
  /*flex-wrap: wrap;
    justify-content: center;*/
}

.SeriesText {
}

.PlayersIcon {
  position: absolute;
  right: 5px;
  top: 45px;
  z-index: 1;
}

.User {
  background-image: url("../../assets/Playersiconsverticalsm.png");
  width: 25px;
  height: 25px;
}
.Chat {
  background-image: url("../../assets/Playersiconsverticalsm.png");
  width: 25px;
  height: 25px;
  background-position: 0 -25px;
}
.Share {
  background-image: url("../../assets/Playersiconsverticalsm.png");
  width: 25px;
  height: 25px;
  background-position: 0 -50px;
}
.Heart {
  background-image: url("../../assets/Playersiconsverticalsm.png");
  width: 25px;
  height: 25px;
  background-position: 0 -75px;
}
.Music {
  background-image: url("../../assets/Playersiconsverticalsm.png");
  width: 25px;
  height: 25px;
  background-position: 0 -100px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="SeriesText"] {
    width: max-content;
    font-size: 15px;
    margin: 10px;
  }
  [class*="SeriesTitleDiv"] {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  /* For mobile phones: */
  [class*="SeriesText"] {
    width: 100%;
    font-size: 15px;
    margin: 20px 0 0 0;
  }
}
