.Root {
  background-color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: -2px -2px 2px 2px #02020254, 2px 2px 2px 2px #02020254;
  /* overflow-y: scroll; */
  /* max-width: 300px; */
  /* max-height: 350px; */
}

.Item {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 20px;
}
.Line1 {
  color: black;
  margin: 0px;
}
.Line2 {
  color: gray;
  font-size: smaller;
  margin: 0px;
}
