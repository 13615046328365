

.ContributorFooter{
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4rem;
    }
  .ContributorFooter span{
  color: #2c65f2;
  font-weight: bold;
  
  }
  .ContributorFooter p{
    color: #7f7f7f;
    font-size: .8rem;
    margin-bottom: 2rem;
  }