.BlogDetail {
  width: 100%;
}
.Headings{
  text-align: center;
  text-align: center;

}
.Headings h1{
  color: #999999;
  font-size: 5vw;
}
.Headings h4{
  color: #605f5f;
  font-size: 2.5vw;
}
.Detailing{
  padding: 0px 40px;
}