.Test{
    background-color: #080707f0 ;
    color: #bab8b8;
    font-size:12px;
    padding-top: 0px;
    width: 82%;
    left: 10%;
    border: 1px solid #19b2ae;
}
.Test input{
    height: calc(1em + .5rem + 2px);
    border-radius: .1rem;
}
label {
    margin-bottom: 0rem;
}
.Jion{
   background-color: #e8e4e5; 
   border: 2px solid #19b2ae;
   width: 100%;
   padding: 0;
   color: #000;
   font-size: 1rem;
   text-transform: uppercase;
   box-shadow: inset 0 0 3px #19b2ae;
   font-family: 'Myriad Pro Bold';
   transition: 1s;
}
.Jion:hover{
    color: #000;
  background-color: #e8e4e5; 
  border: 2px solid #19b2ae;
  letter-spacing: 2px;
  transition: 1s;
 }
 .Jion:disabled:hover{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    }
.logassocial{
    width: 100%;
    padding: 2px 0 0 0;
    margin: 10px 0;
    background-color: #4848c2;
    border: none;
    font-size: 1.1rem;
    border-radius: 1px;
}
.logassocial:hover{
    background-color: #0a0a90;
    border: none;
}
.Signin span{
    cursor: pointer;
}
.Signin{
    text-align: center;
}
.OR{
    margin-top: 15px;
    font-size: 1.4rem;
    color: #19b2ae;
    font-family: 'Myriad Pro Bold';
    position: relative;
    text-align: center;
}
.OR:after{
    content: " ";
    width: 40%;
    height: 1px;
    position: absolute;
    background-color: #19b2ae;
    left: 0;
    top: 17px;
}
.OR:before{
    content: " ";
    width: 40%;
    height: 1px;
    position: absolute;
    background-color: #19b2ae;
    right: 0;
    top: 17px;
}
.Agree{
    font-size: .8rem;
    text-align: center;
    margin-bottom: 20px;
}
.Agree input{
    margin-top: -3px;
}
/* p{
    text-align: center;
} */
.ModalBody{
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 7px  !important;
}
.ModalBody div{
   /* text-align: center; */
   margin-top: 10px;
}
.MHeader{
    border-bottom: none;
    padding: 0.4rem;
}
.MHeader span{
    display: inline-block;
    position: absolute;
    left: 47%;
    color: #fff;
    opacity: 1;
    font-size: 20px;
    font-weight: 100;
}