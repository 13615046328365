.ContriTwoTxt{
  font-size: 1.1rem;
  text-align: center;
  font-weight: 700;
}
.Check{
  color: #bfbfbf;
  margin-top: 4rem;
  font-size: 1.3rem;
}
.ContributorContent{
  font-size: 16px;
}
.UlHeading{
  color: #7f7f7f;
    font-size: 1.2rem;
    margin-bottom: 2rem;
}
.ContributorContent ul{
  padding-left: 10px;

  }
.ContributorContent ul li{
  font-size: .8rem;
  list-style-type: none;
  position: relative;
  text-transform: uppercase;
  letter-spacing: .1px;

}
.ContributorContent ul li:before{
position: absolute;
content: "-";
left: -8px;
text-transform: uppercase;
  }
 
    .LongImg{
      margin:auto;
    }