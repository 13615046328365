/** {
  background-color: rgb(236, 234, 234);
}*/

.AboutText {
  font-family: Myriad Pro Bold;
  padding: 5px 140px;
  margin-top: -80px;
  font-size: 16rem;
  color: #fff;
  text-shadow: 4px 2px 3px #afadad;
}
.WhyUs {
  width: 100%;
}
.ImgBanner {
}
.ImgBanner img {
  width: 100%;
  padding: 83px 0px 55px 0px;
}
.BelowBanner {
  font-family: Myriad Pro Bold;
  padding: 20px 0px;
  font-size: 2.5rem;
  color: #fff;
  text-shadow: 2px 2px 3px #afadad;
}

.BelowBanner span {
  color: #ff8b00;
  font-weight: bold;
}
.Text {
  /*padding: 20px 0;*/
  padding: 0px 0 20px 0;
  margin-top: -35px;
}
.Text p {
  margin-bottom: 0;
  color: #f16e24;
  font-size: 1.3rem;
}
.TextGray {
  color: #bebebe;
  font-size: 0.9rem;
}

.TextGray p {
  margin-bottom: 0;
  line-height: 30px;
}
.TextOrange {
  color: #f16e24;
  padding: 20px 0;
}
.IntroVideo {
  border-radius: 20px;
  box-shadow: 1px 1px 3px #c3bfbf;
}
.IntroVideo img {
  width: 100%;
}
.WhyusDescr {
  /*padding: 30px 0;*/
  padding: 30px 0px 30px 20px;
}
.RowOne {
  /*padding: 30px 0;*/
  padding: 160px 0 30px 0px;
}
.ContentArea {
  padding-left: 30px;
  /*padding-right: 154px;*/
  padding-right: 11vw;
  padding-bottom: 50px;
  margin: auto;
}
.ContentArea h6 {
  color: #f99207;
  font-size: 1.1rem;
  margin-top: 4rem;
}
.ContentArea p {
  color: #838282; /* #8d8d8d;*/
  font-size: 1rem;
  margin-top: 20px;
}
.Orange {
  color: #f16e24;
}

@media (max-width: 767px) {
  .AboutText {
    font-family: Myriad Pro Bold;
    padding: 45px 0px 45px 54px;
    /*margin-top: -80px;*/
    width: 100%;
    font-size: 5rem;
    color: #fff;
    text-shadow: 4px 2px 3px #afadad;
  }
  .Text {
    /*padding: 20px 0;*/
    padding: 0px 0 0px 0px;
    font-size: 2rem;
    margin-top: -35px;
  }
  .Text p {
    margin-bottom: 0;
    color: #f16e24;
    font-size: 1.1rem;
  }
  .TextGray p {
    margin-bottom: 0;
    padding-top: 20px;
  }
  .Flip {
    flex-direction: column-reverse;
  }
  .BelowBanner {
    font-size: 1.5rem;
    padding-bottom: 0px;
  }
  .ContentArea {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -50px;
    margin-top: 20px;
  }
  .ContentArea h6 {
    font-size: 0.8rem;
    margin-top: 1.6rem;
  }
  .ContentArea p {
    font-size: 0.75rem;
    margin-top: 15px;
  }
  .WhyusDescr {
    padding: 0px 0;
  }
  .RowOne {
    padding: 0px 0;
  }

  div[style] {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 400px) and (max-width: 600px) {
  .AboutText {
    padding: 100px 15% 100px 14%;
  }
  .Text p {
    font-size: 1rem;
  }
  .TextGray {
    line-height: 25px;
  }
  .TextGray p {
    padding-top: 15px;
    margin-bottom: -15px;
  }
}
@media only screen and (max-width: 400px) {
  .AboutText {
    padding: 100px 0px 100px 28px;
  }
  .Text p {
    font-size: 1rem;
  }
  .TextGray {
    line-height: 25px;
  }
  .TextGray p {
    padding-top: 15px;
    margin-bottom: -15px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 769px) {
  .AboutText {
    font-size: 10rem;
    padding: 100px 0px 100px 75px;
  }
}
