.FooterItems { margin-top: 9px;
    border: 1px solid #e8e8e8;
    padding: 5px 0 0 0px;
    box-shadow: 0px -2px 2px #b1b1b1;
    color: #9e9e9e;
    position: relative;
    background-color: #f9f9f9;
}
.Norow{
  margin-left: 0px;
  margin-right: 0px;
}

.BtmFtr{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px 40px;
  box-shadow: 0px -1px 1px #ece7e7;
  margin-top: 30px;

}
.Enjoy{
  position: absolute;
  right: 20px;
  width: 130px;
  bottom: 0;
}
.BtmFtr p{
  font-size: 12px;
  margin-bottom: 0;
  color: #c6c6c6;
}
.OuterSocial{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.OuterSocial a{
  padding: 0 5px;
}
.FooterItems .Norow div:first-child{
  padding-right: 18px;
  margin: auto;
}
.SocialInsta{
  width: 24px;
  height: 24px;
background-image: url("../../../assets/socialicon.png");
background-position: 2px -1px;
 }
 .SocialLinked{
  width: 24px;
  height: 24px;
background-image: url("../../../assets/socialicon.png");
background-position: -33px -1px;
 }
 .SocialFace{
  width: 24px;
  height: 24px;
background-image: url("../../../assets/socialicon.png");
background-position: -65px -1px;
 }
 .SocialTwitt{
  width: 24px;
  height: 24px;
background-image: url("../../../assets/socialicon.png");
background-position: -96px -1px;
 }
@media (min-width: 500px) {
  .FooterItems {
    
  }
}
