
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.formitems {
  margin-top: 10px;
}
.bodycontaineryy {
  /* display: flex;
  justify-content: center; */
  width: 80%;
  text-align: center;
  background-color: #f7f7f7;
}
.input[type="radio"]:after {
  width: 0px;
  height: 0px;
  top: -0px;
  left: 0px;
  content: "";
  display: inline-block;
}
.input[type="radio"]:checked:after {
  width: 0px;
  height: 0px;
  border-radius: -1px;
  top: -16px;
  left: -4px;
  background: white;
  content: "✔";
  font-size: 65%;
  color: #f8faf8;
  font-weight: initial;
  position: relative;
  border: 0px solid white;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input[type="radio"]:checked + span {
  color: #0be830;
}
.imagepre {
  height: 60px;
  width: 30px;
  background-color: #ffffff;
  transform: rotate(180deg);
}
.imagepre:hover {
  height: 60px;
  width: 30px;
  background-color: #ffffff;
  transform: rotate(180deg);
  z-index: -1;
}
.armpre {
  width: 30px;
  margin-top: -17px;
  margin-left: -14px;
  border-radius: 20px;
  z-index: -18;
  border-style: hidden;
}
.imagenext {
  height: 60px;
  width: 30px;
  background-color: #ffffff;
}
.custombutton {
  border-style: hidden;
  background-color: #f7f7f7;
}
.imagenext:hover {
  height: 60px;
  width: 30px;
  background-color: #ffffff;
  z-index: -1;
}
.input[type="radio"]:checked {
  border-radius: -1px;
  top: 0px;
  left: 0px;
  background: white;
  content: "✔";
  font-size: 120%;
  color: #e9efea;
  font-weight: 700;
  position: relative;
  border: 0px solid white;
  box-sizing: border-box;
  appearance: none;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  outline: 2px solid #cbd7cd;
  border: 6px solid #0be830;
  width: 10px;
  height: 10px;
}
.input[type="radio" i] {
  background-color: initial;
  cursor: default;
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
  box-sizing: border-box;
  margin: 0px 8px 0px 8px;
  padding: initial;
  border: initial;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  outline: 2px solid #b5b3b7;
  border: 6px solid #ffffff;
  width: 10px;
  height: 10px;
}




.Selected {
  color: green;
}

.Unselected {
  color: black;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* Timeline */

.timeline::before {
  top: 40px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #832b2b;
  left: 50%;
  margin-left: -1.5px;
}

hr.new5 {
  border: 10px solid green;
  border-radius: 5px;
}


.timelineBadge i,
.fa,
.glyphicon {
  top: 2px;
  left: 0px;
}

.timelineBadge.primary {
  background-color: #1f9eba;
}

.timelineBadge.info {
  background-color: #5bc0de;
}

.timelineBadge.success {
  background-color: #59ba1f;
}

.timelineBadge.warning {
  background-color: #d1bd10;
}

.timelineBadge.danger {
  background-color: #ba1f1f;
}

.timelineBadge {
  color: #fff;
  width: 38px;
  height: 37px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: 18px;
  left: 50%;
  margin-left: -25px;
  background-color: #f1f0f0;
  border: 3px solid #ffffff;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}
.changecolor {
  --customback: #f1f0f0;
}
.eventtrriger {
  --customback: #27da11;
}
.eventtrrigerchangecolor {
  --customback: #a39d9d;
}
.timelineBadge1 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 54px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  border: 2px solid #0be830;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  box-shadow: 0px 3px 3px #beb3b3;
}

.timelineBadge1::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 54%;
  left: 112%;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 8vw;
  box-shadow: -1px 3px 3px #e3dcdc;
  height: 8px;
  background-color: var(--customback, #f1f0f0);
}
.newtimlepaintl1 {
  border-style: solid;
  border-color: #0be830 !important;
  border-width: 3px;
  margin-left: -3px;
}
.newtimlepaintl2 {
  --myvalue: #0be830;
  --borderwith: 12px;
}
.timelinePane2css {
  border-style: solid;
  border-color: #0be830 !important;
  border-width: 3px;
  margin-left: -3px;
}
.timelinePane2cssafter {
  --myvalue: #0be830;
  --borderwith: 12px;
  --bottomval: 40px;
}

.timelineBadge2 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  box-shadow: 0px 3px 3px #beb3b3;
  top: auto;
  bottom: 10px;
  left: 135px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  border: 2px solid #0be830;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge2::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 54%;
  left: 112%;
  box-shadow: -1px 3px 3px #e3dcdc;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 8vw;
  height: 8px;
  background-color: var(--customback, #f1f0f0);
}
.timelineBadge3 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 216px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  border: 2px solid #0be830;
  box-shadow: 0px 3px 3px #beb3b3;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge3::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 54%;
  left: 112%;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  box-shadow: -1px 3px 3px #e3dcdc;
  width: 8vw;
  height: 8px;
  background-color: var(--customback, #f1f0f0);
}
.timelineBadge4 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  box-shadow: 0px 3px 3px #beb3b3;
  left: 295px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  border: 2px solid #0be830;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge4::after {
  content: "";
  display: block;
  box-shadow: -1px 3px 3px #e3dcdc;
  position: absolute;
  z-index: -1;
  top: 54%;
  left: 112%;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 8vw;
  background-color: var(--customback, #f1f0f0);
  height: 8px;
}
.timelineBadge5 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 376px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  border: 2px solid #0be830;
  box-shadow: 0px 3px 3px #beb3b3;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge5::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  box-shadow: -1px 3px 3px #e3dcdc;
  top: 54%;
  left: 112%;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 8vw;
  height: 8px;
  background-color: var(--customback, #f1f0f0);
}
.timelineBadge6 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 455px;
  margin-left: -25px;
  background-color: #f1f0f0;
  box-shadow: 0px 3px 3px #beb3b3;
  border-style: solid;
  border: 2px solid #0be830;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge6::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 54%;
  left: 112%;
  box-shadow: -1px 3px 3px #e3dcdc;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 8vw;
  background-color: var(--customback, #f1f0f0);
  height: 8px;
}
.timelineBadge7 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 536px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  box-shadow: 0px 3px 3px #beb3b3;
  border: 2px solid #0be830;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge7::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  box-shadow: -1px 3px 3px #e3dcdc;
  top: 54%;
  left: 112%;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 8vw;
  height: 8px;
  background-color: var(--customback, #f1f0f0);
}
.timelineBadge8 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 615px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  box-shadow: 0px 3px 3px #beb3b3;
  border: 2px solid #0be830;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge8::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 54%;
  left: 112%;
  transform: translateY(-50%);
  background-color: #f1f0f0;
  width: 4vw;
  height: 8px;
  box-shadow: -1px 3px 3px #e3dcdc;
  background-color: var(--customback, #f1f0f0);
}
.timelineBadge9 {
  color: #fff;
  width: 21px;
  height: 22px;
  line-height: 52px;
  font-size: 22px;
  box-shadow: 0px 3px 3px #beb3b3;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 10px;
  left: 695px;
  margin-left: -25px;
  background-color: #f1f0f0;
  border-style: solid;
  border: 2px solid blue;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timelineBadge::after {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 48px;
  background-color: var(--customback, #f1f0f0);
  transform: translateY(-50%);
  background-color: #2c3e50;
  width: 29vw;
  height: 5px;
}

.timelinePanel::before {
  position: absolute;
  top: 26px;
  right: -16px;
  display: inline-block;
  border-top: 16px solid transparent;
  border-left: 16px solid #777;
  border-right: 0 solid #777;
  border-bottom: 16px solid transparent;
  content: " ";
}

.timelineTitle {
  margin-top: 0;
  color: inherit;
}

.timelineTitle1 {
  margin-top: -13px;
  color: inherit;
  margin-left: -13px;
  color: blue;
}

.timelineTitle2 {
  margin-top: -13px;
  color: inherit;
  margin-left: -13px;
  color: rgb(190, 190, 226);
}
.timelineTitle3 {
  margin-top: -13px;
  color: inherit;
  margin-left: -13px;
  color: rgb(190, 190, 226);
}
.timelineTitle5 {
  margin-top: -13px;
  color: inherit;
  margin-left: -13px;
  color: rgb(190, 190, 226);
}
.timelineTitle7 {
  margin-top: -13px;
  color: inherit;
  margin-left: -13px;
  color: rgb(190, 190, 226);
}
.timelineBody > p,
.timelineBody > ul {
  margin-bottom: 0;
}

.timelineBody > p + p {
  margin-top: 5px;
}

.timelinePanel {
  position: relative;
  width: 46%;
  float: left;
  right: 16px;
  border: 1px solid #777;
  background: #ffffff;
  border-radius: 2px;
  padding: 20px;
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
}



.timelineItem1 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}

.timelineItem2 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timelineItem3 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timelineItem4 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timelineItem5 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timelineItem6 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.nexticon {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-right: 50px solid transparent;
  border-top: 35px solid #b9bcbf;
  border-left: 50px solid transparent;
  content: " ";
  display: inline-block;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}
.nexticongreen {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-right: 50px solid transparent;
  border-top: 35px solid #0be830;
  border-left: 50px solid transparent;
  content: " ";
  display: inline-block;
  transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
}

.preimageicon {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-right: 50px solid transparent;
  border-top: 35px solid #b9bcbf;
  border-left: 50px solid transparent;
  content: " ";
  display: inline-block;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.preimageicongreen {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-right: 50px solid transparent;
  border-top: 35px solid #0be830;
  border-left: 50px solid transparent;
  content: " ";
  display: inline-block;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.timelineItem7 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timelineItem8 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}
.timelineItem9 {
  display: table-cell;
  height: 120px;
  width: 20%;
  min-width: 80px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
}


.timeline {
  list-style: none;
  padding: 20px;
  position: relative;
  margin-left: 50px;
}

.timelineHorizontal::before {
  height: 3px;
  top: auto;
  bottom: 26px;
  left: 56px;
  right: 0;
  width: 100%;
  margin-bottom: 20px;
}

/* .timelinePanel::before {
  top: auto;
  bottom: -14px;
  left: 6px !important;
  right: auto;
  border-right: 15px solid transparent !important;
  border-top: 15px solid #ebedef !important;
  border-bottom: 0 solid #ebedef !important;
  border-left: 15px solid transparent !important;
}

.timelinePanel {
  top: auto;
  bottom: 44px;
  display: inline-block;
  float: none !important;
  left: 23px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
} */

.timelinePanel1 {
  top: auto;
  bottom: 2px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.newtimeplain1 {
  border-style: solid;
  border-color: blue;
  border-width: 3px;
  margin-left: -3p;
}
.newtimeplain1::before {
  top: auto;
  bottom: -12px;
  left: 10px !important;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 12px solid #0b2df0 !important;
  border-bottom: 0 solid #0b2df0 !important;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
}
.timelinePanel1::before {
  top: auto;
  bottom: -12px;
  left: 10px !important;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
}
.timelinePanel3 {
  top: auto;
  bottom: 2px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel3::before {
  top: auto;
  bottom: -12px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
}
.timelinePanel5 {
  top: auto;
  bottom: 2px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel5::before {
  top: auto;
  bottom: -12px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
}
.timelinePanel7 {
  top: auto;
  bottom: 2px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel7::before {
  top: auto;
  bottom: -12px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
}
.timelinePanel9 {
  top: auto;
  bottom: 2px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel9::before {
  top: auto;
  bottom: -12px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
}
.step1 {
  position: absolute;
  top: -27px;
  left: -4px;
  width: 53px;
  font-weight: bold;
  color: blue;
}
.step2 {
  position: absolute;
  top: 43px;
  left: 0px;
  width: 53px;
  font-weight: bold;
  color: #ca0dd7;
}
.step3 {
  position: absolute;
  top: -27px;
  left: -4px;
  width: 53px;
  font-weight: bold;
  color: #e90f0f;
}
.step5 {
  position: absolute;
  top: -27px;
  left: -4px;
  width: 53px;
  font-weight: bold;
  color: blue;
}
.step4 {
  position: absolute;
  top: 42px;
  left: 0px;
  width: 53px;
  font-weight: bold;
  color: #f99207;
}
.step6 {
  position: absolute;
  top: 42px;
  left: 0px;
  width: 53px;
  font-weight: bold;
  color: #ca0dd7;
}
.step7 {
  position: absolute;
  top: -27px;
  left: -4px;
  width: 53px;
  font-weight: bold;
  color: #e90f0f;
}
.step8 {
  position: absolute;
  top: 42px;
  left: 0px;
  width: 53px;
  font-weight: bold;
  color: #f99207;
}
.step9 {
  position: absolute;
  top: -1px;
  left: -64px;
  font-weight: bold;
  color: #f99207;
}
.timelinePanel2 {
  top: auto;
  bottom: -95px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}

.timelinePanel2::before {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  transform: rotate(180deg);
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
  bottom: var(--bottomval, 36px);
}
.timelinePanel4 {
  top: auto;
  bottom: -95px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel4::after {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  transform: rotate(180deg);
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
  bottom: var(--bottomval, 36px);
}
.timelinePanel6 {
  top: auto;
  bottom: -95px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel6::after {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  transform: rotate(180deg);
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
  bottom: var(--bottomval, 36px);
}
.timelinePanel8 {
  top: auto;
  bottom: -95px;
  display: inline-block;
  float: none !important;
  left: 20px !important;
  height: 16%;
  right: 0px !important;
  width: 23%;
  background-color: #ebedef;
  border-radius: 50%;
  border-color: aliceblue;
  margin-bottom: 25px;
  position: relative;
  padding: 20px;
  box-shadow: 0 6px 5px rgb(0 0 0 / 18%);
}
.timelinePanel8::after {
  top: auto;
  bottom: 36px;
  left: 10px;
  right: auto;
  border-right: 10px solid transparent;
  border-top: 15px solid #ebedef;
  border-bottom: 0 solid #ebedef;
  border-left: 10px solid transparent;
  position: absolute;
  content: " ";
  display: inline-block;
  transform: rotate(180deg);
  border-top-color: var(--myvalue, #ebedef);
  border-top-width: var(--borderwith, 15px);
  bottom: var(--bottomval, 36px);
}
.timelineItem::before,
:after {
  /* display: none; */
}

.timelineBadge {
  top: auto;
  bottom: 0px;
  left: 43px;
}

/* .timelineItem {
  display: table-cell;
  height: 280px;
  width: 20%;
  min-width: 200px;
  float: none !important;
  padding-left: 0px;
  padding-right: 20px;
  margin: 0 auto;
  vertical-align: bottom;
} */

.containerblow {
  display: inline-block;
  width: 102%;
  overflow-y: auto;
  min-height: 266px;
}

.timelineHorizontal {
  list-style: none;
  position: relative;
  padding: 20px 0px 20px 0px;
  display: inline-block;
  text-align: center;
}
