.Chat {
  width: 100%;
  margin: auto;
  margin-bottom: 15px;
  font-size: .7rem;
 
}

.ChatBox {
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;
  color: #a2a2a2;
  margin-top: 15px;
}
.MeProfile{
  text-align: center;
  font-size: .6rem;
  color: #fff;
  padding: 10px 20px;
  position: relative;
}
.MeProfile p{
margin-top: 13px;
font-size: .5rem;
}
.Norow{
  margin-left: 0px;
  margin-right: 0px;
}
.ChatLeft{
  background-color: #303030;
  box-shadow: 0px 0px 5px #655e5e;
  border-bottom-left-radius: 120px;
  border-bottom-right-radius: 20px;
  position: relative;
  margin-top: 20px;
  margin-right: 40px;

}
.ChatLeft:before{
  transform: rotate(45deg);
  position: absolute;
  content: "";
  width: 18px;
  height: 18px;
  background-color: #303030;
  top: -7px;
  left: 83px;
}


.ChatRight{
  background-color: #f4f4f4;
  box-shadow: 0px 0px 5px #655e5e;
  border-radius: 20px;
  min-height: 425px;
  overflow: hidden;
  padding: 15px 40px;
  margin-top: 20px;

}
.ChatHead{
  box-shadow: 0px 7px 6px #251e1e;
  font-size: .7rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3px 15px 0 5px;
}
.ChatHead p{
 margin-bottom: 0;
}
.Contact{
  padding: 25px 0px 0 0;
}
.ChatListing{
overflow: auto;
max-height: 250px;
border-bottom-left-radius: 10px;
font-size: 8px;
}
.ChatListing div{
  margin-right: 10px;
  background-color: #fff;
 
  }
.ChatListing ul{
  margin-bottom: 0;
  padding: 0px 8px;
}
.ChatListing ul>li{
  padding: 5px 5px 5px 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
}
.ChatListing ul>li:nth-child(even){
  background-color: #f9f9f9;
}

.ChatListing::-webkit-scrollbar {
  width: .6rem;
}
.ChatListing::-webkit-scrollbar-track {

  border-radius: 10px;
  background-color:#000;
  width: .9rem;
}
 
.ChatListing::-webkit-scrollbar-thumb {
  background: #4b4b4bed; 
  border-radius: 10px;
}
.ChatListing::-webkit-scrollbar-thumb:hover {
  background: #4b4b4bed; 
}
.ContactHead{
  margin-right: 20px;
  background-color: #303030;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: -2px -2px 4px #1f1e1e;
  padding: 16px 12px 0px 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
}
.Setting{
  position: absolute;
  right: 3px;
  font-size: 8px;
  top: 3px;
}
.ChattingBox{
  height: 96%;
  margin: 0px 10px 0px 0px;
  max-height: 285px;
  overflow: auto;
  padding: 10px 10px;
}
.ChattingUpper{
  background-color: #f4f4f4;
  border-radius: 10px;
  margin-right: 10px;
}
.ChattingBox::-webkit-scrollbar {
  width: .5rem;
  height: 20%;
}
.ChattingBox::-webkit-scrollbar-track {

  border-radius: 10px;
  background-color: #eeeeee;
}
 
.ChattingBox::-webkit-scrollbar-thumb {
  background: #d0d0d0; 
  border-radius: 10px;
}
.ChattingBox::-webkit-scrollbar-thumb:hover {
  background: #4b4b4bed; 
}
.Dropmsg{
  background-color: #f4f4f4;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
  padding: 5px 10px;
  min-height: 60px;
  font-size: .5rem;
  letter-spacing: 1px;
  
}
.Name{
  color:#fff;
}
.Windowicon{
  margin-right: 10px;
  color: #fff;
  font-size: .6rem;
}
.Refresh i{
  transform: rotate(-145deg) scaleX(-1);
  font-size: 8px;
}
.ComingFirst, .GoingFirst{
  display: inline-block;
  padding: 10px 10px 10px 50px;
  background-color: #fcfbf6;
    box-shadow: 0px 2px 4px #888585;
  font-size: 1rem;
  border-radius: 5px;
  position: relative;
  margin: 0px 10px;
}
.ComingFirst{
  color: #f99207;
}


.ComingFirst::after{
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: -11px;
  left: -6px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 18px solid #fcfbf6;
  transform: rotate(-31deg);
}
.GoingFirst::after{
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: -11px;
  right: -6px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 18px solid #fcfbf6;
  transform: rotate(31deg);
}
.ComingFirst::before{
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: -14px;
  left: -8px;
  border-left: 8px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 21px solid #c7c1c1;
  transform: rotate(-31deg);
}
.GoingFirst::before{
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: -14px;
    right: -8px;
    border-left: 8px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 21px solid #c7c1c1;
    transform: rotate(31deg);
}
.History{
  margin-top: 5px;
  font-size: 9px;
  cursor: pointer;
}
.SendSec{
  display: flex;
  padding: 7px 5px;
}
.SendSec p{
  width: 50%;
  margin-bottom: 0;
}
.SendSec p span{
padding: 0 5px;
}
.Sendbtn{
  background: #515151;
  outline: none;
  border: none;
  color: #fff;
  padding: 2px 16px 1px 16px;
  border-radius: 5px;
  font-size: .8rem;
  letter-spacing: 1px;
}
.MsgBlockright{
  text-align: right;
}
.MsgBlockright .Me{
margin-bottom: 0;
padding-right: 30px;
}
.Msgtime{
color: #000;
font-size: .5rem;
padding-right: 20px;
}
.Msgtime span{
 margin-left: 8px;
  }
  .MsgBlockleft .Me{ 
    margin-bottom: 0;
    padding-left: 30px;
    }

    .ActiveMode{
      position: absolute;;
      color: green;
      font-size: 8px;
      top: 8px;
      left: -5px;
  }
  .ActiveModeMe{
    position: absolute;
    color: green;
    font-size: 7px;
    top: 13px;
    left: 20px;
}

.HistorDrop{
  border: none;
  box-shadow: 1px 0px 7px 1px #868383;
  position: relative;
  right: -60px;
  top: 78px;

}
.HistorDrop a:focus, .HistorDrop a:focus, .HistorDrop a:hover{
background-color: #bcbcbc!important;
color: #fff;
}
.HistorDrop:after{
  position: absolute !important;
  content: " ";
  width: 0;
  height: 0;
  top: -8px;
  left: 22%;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid white;
}
.HistorDrop:before{
  position: absolute !important;
  content: " ";
  width: 0;
  height: 0;
  top: -8px;
  left: 22%;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 12px solid #b8b6b6;
}

.DropdownItem{
  padding: 5px 10px;
  font-size: 11px;
  color: #cbcbcb;
  word-break: keep-all;
  white-space: nowrap;
}
.DropdownItem:hover{
  padding: 5px 10px;
  font-size: 11px;
  color: #fff;
  background-color: #bcbcbc;
  word-break: keep-all;
  white-space: nowrap;
}

