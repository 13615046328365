.BlogBanner{
  position: relative;
  height: 100%;
  overflow: hidden;
  margin-bottom: 2rem;
      margin-top: 2rem;
}
.BlogBanner img{
  width: 100%;
}
.BannerText{
  position: absolute;
  top: 100px;
  left: 2%;
  font-size: 14vw;
  color: #fff;
  text-shadow: 2px 2px 4px #7e7d7d;
  font-family: 'Myriad Pro Bold';
}