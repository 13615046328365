.NavItemProfile p{
 font-size: 8px;
 margin-bottom: 0px;
 margin-top: 2px;
 color: #b1aeae;

}

.DropDiv button{
padding:0;
}
.CogIcon{
  float: right;
}
.DropDiv a{
  text-align: left;
  min-width: 180px;
  padding: 2px 12px;
  font-size: 13px;
}
.Divider{
  border-top: 1px solid #a4a4a4;
}
.DropDiv{
  border: none;
  box-shadow: 1px 0px 7px 1px #868383;
  position: relative;
  right: -60px;
  top: 78px;
}
.DropDiv a:focus, .DropDiv a:focus, .DropDiv a:hover{
background-color: #bcbcbc!important;
color: #fff;
}
.DropDiv:after{
  position: absolute !important;
  content: " ";
  width: 0; 
  height: 0; 
  top: -22px;
  left: 44%;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 22px solid white;
}
.DropDiv:before{
  position: absolute !important;
  content: " ";
  width: 0; 
  height: 0; 
  top: -24px;
  left: 42%;
  border-left: 16px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 24px solid #b8b6b6;
}
.DropdownItem.active, .DropdownItem a:active{
  color: #fff;
  background-color: #bcbcbc!important;
}

@media (min-width: 500px) {
  .NavItemProfile {
  }
}
