.Earnings{
    width: 100%;
}
.Earningbox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    margin-top: .5rem;
}
.Earningbox div {
    margin: 20px 0px;
    border-right: 1px solid #cbcbcb;
    padding:20px;
    width: 20%;
    text-align: center;
}
.Earningbox div:last-child {
    border-right: none;
}
.Earningbox div p{
    margin-bottom: 0;
 }
 .OuterTable{
     margin-top: 2rem;
     box-shadow: 0px -1px 8px #c6c4c4;
 }
 .OuterTable table{
 font-size: 1.2rem;
}
.OuterTable th{
    color: #a3a3a3;
}

.OuterTable table tr td:last-child{
    color: #52a27a
   }
.ShowFliter{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 60%;
    margin-top: 2rem;
}
.ShowFliter input{
    background-color: #fff0;
    color: #a3a3a3;
    font-size: 1.2rem;
    width: 95%;
    text-align: center;

}
.ShowTxt{
    align-self: center;
    font-size: 1.2rem;
    color: #a3a3a3;
    padding-right: 20px;
}

@media (min-width: 1440px) {
    .ShowFliter input{
        font-size: 1.4rem;
    }
    .ShowTxt{
        font-size: 1.4rem;
    }
  
  }

@media (max-width: 767px) {
    .ShowFliter{
        width: 100%;
    }
    .ShowFliter input{
        font-size: .8rem;
    }
    .ShowTxt{
        font-size: .8rem;
    }
  
  }