.MyVideos {
  width: 100%;
  margin-top: 2rem;
}
.Head {
  border-radius: 50px;
  display: inline-block;
  background: #b0b0b0;
  padding: 3px 15px;
  color: #fff;
  margin-bottom: 0;
  font-size: 1.2rem;
  padding-top: 7px;
}
.MyvideoTB th,
.MyvideoTB td {
  border: none;
  padding: 0.2rem 0.9rem;
  vertical-align: middle;
  color: #707070;
  border-bottom: none;
}
.MyvideoTB td:last-child {
  cursor: pointer;
}
.MyvideoTB td:first-child,
.MyvideoTB th:first-child {
  padding: 0.2rem 0.9rem 0.2rem 0;
  border: none;
}
.MyvideoTB th:last-child,
.MyvideoTB td:last-child {
  background-color: #f9f9f9;
  border-bottom: none;
}
.MyvideoTB th:nth-last-child(2),
.MyvideoTB td:nth-last-child(2) {
  border: none;
}
.MyvideoTB th:last-child,
.MyvideoTB td:last-child {
  background-color: #f9f9f9;
  width: 3%;
  border: none;
  color: #b2b2b2;
}
.MyvideoTB th,
.MyvideoTB td {
  border-right: 1px solid #dee2e6;
}
.MyvideoTB tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.GreenIcon {
  color: green;
  margin-right: 3rem;
}

.GrayIcon {
  margin-right: 3rem;
  color: gray;
}

/* onoff */

.MySwitch {
  transform: scaleX(-1);
  height: fit-content;
  text-align: center;
}

/* onoff */
.HeadSeries {
  border-radius: 50px;
  display: inline-block;
  background: #b0b0b0;
  padding: 3px 15px 3px 15px;
  color: #fff;
  margin-bottom: 0;
  font-size: 1.2rem;
  padding-top: 7px;
}

.Root {
  background-color: white;
  border-radius: 10px 10px 10px 10px;
  box-shadow: -2px -2px 2px 2px #02020254, 2px 2px 2px 2px #02020254;
  /* max-width: 300px; */
  background-color: lightgray;
}
