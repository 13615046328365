.PaymentDetails{
    width: 100%;
}

.Line1{
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin: auto;
}


.part1{
    width: 4%;
    text-align: center;
    font-size: 1.5rem;
    font-style: italic;
    margin: 0 10px;
}
.part2{
    width: 60%;
    margin: 0 10px;
}
.Payment{
    text-align: center;
    padding: 30px 0;
    color: #9b9b9b;
    font-size: 1.4rem;
}
.Payment p{
   margin-bottom: 0;
}

.MySelect{
    background-color: #ffffff0a;
}
.MySelect:focus {
    color: #848484;
    background-color: #fff0;
    border-color: #d0cece;
    outline: 0;
    box-shadow: none;
}

/* onoff */
.OnOff{
    width: 20%;
    height: 38px;
    display: flex;
    flex-wrap: nowrap;
    text-transform: uppercase;
    justify-content: space-around;
    border: 1px solid #e1e1e1;
    padding-top: 8px;
    font-size: 1.2rem;
    margin: 0 10px;
}
.MySwitch{
    transform: scaleX(-1);
    height: fit-content;
}

.On{
    color: #f79200;
}
.Off{
    color: #848484;
}
/* onoff */
