.FooterHeader{
  border: 1px solid #e8e8e8;
  padding: 5px 0 0 0px;
  box-shadow: 0px -2px 1px #b1b1b1;
  background-color: #f9f9f9;
}
.FooterHeader div{
  text-align: center;
}
.FooterHeader p{
 margin-bottom: 0px;
 color: #fff;
 text-shadow: 2px 2px 3px #afadad;
}
.FooterHeader p:nth-child(2){
font-size: 16px;
 }
.FLogo a{
  color: #fff;
  text-shadow: 2px 2px 3px #afadad;
  font-size: 18px;
 }

.FooterHeader p:first-child{
  margin-bottom: 0px;
  font-size: 22px;
}
.Norow{
  margin-left: 0;
  margin-right: 0;
}
/* .FLogo img{
  width: 60%;
} */
